import classnames from 'classnames';
import {
  LeftSidebar,
  RightSidebar,
  useColumnsState,
  useLeftSidebarState,
  useTableOptions,
  ProductTour,
  ShowSidebarTooltipButton,
  DateWarningAlert,
  Loader,
} from 'components';
import dayjs from 'dayjs';
import {
  useKeyExistsState,
  useLocalStorageToggle,
  useLogsState,
  useRequest,
  useSubscriptionRequest,
  useToggle,
  useUrlState,
} from 'hooks';
import { delimiter } from 'kfuse-constants';
import React, {
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Play, Pause } from 'react-feather';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useLatest } from 'react-use';
import {
  buildQuery,
  formatSeriesToLogCountsWithStepSize,
  getCustomerLabelValuesForLogs,
  getFpList,
  getLogsFavouriteFacetsByCursor,
  getServiceGroupLabelValues,
  getServiceGroupLabels,
} from 'requests';
import {
  DateSelection,
  FingerprintQueryProps,
  LabelsProps,
  LogsMetricQueryProps,
  SelectedLog,
  User,
} from 'types';
import { getLogsPrevAndNextHandlers } from 'utils';
import {
  useLogsLiveTail,
  useLogsTable,
  useLogsWorkbooksState,
  useQueryScheduler,
} from './hooks';
import LogsAnalytics from './LogsAnalytics';
import LogsFingerprintsList from './LogsFingerprintsList';
import LogsQueryTimer from './LogsQueryTimer';
import LogsSearch from './LogsSearch';
import LogsSelectedLog from './LogsSelectedLog';
import LogsSelectedLogTitle from './LogsSelectedLogTitle';
import LogsSidebar from './LogsSidebar';
import LogsTimeline from './LogsTimeline';
import LogsTable from './LogsTable';
import LogsTransactions from './LogsTransactions';
import LogsWorkbookHistory from './LogsWorkbookHistory';
import { getColumns, MESSAGE, SOURCE, TIMESTAMP } from './constants';
import { useLogsAnalyticsChart } from './LogsAnalytics/hooks';
import LogsAnalyticsQueryBuilder from './LogsAnalytics/LogsAnalyticsQueryBuilder';
import LogsTabsContainer from './LogsTabsContainer';
import Datepicker from 'composite/Datepicker';
import { LogsSearchRefreshButton } from './LogsSearchBar';
import { isTabAnalytics } from './utils';
import useDebouncedEffect from 'use-debounced-effect';
import { generateLogPeriodOptions } from 'composite/utils';
import { useFeatureFlag } from 'configcat-react';
import { useLogsPageStateContext } from 'context/PageStateProvider';
import CustomerFilter from 'screens/Services/CustomerFilter';

const queryBuilder = (args) => {
  const { bucketSecs, logsState } = args;
  const { date } = logsState;
  const { startTimeUnix, endTimeUnix } = date;
  const endTime = dayjs.unix(endTimeUnix);
  const durationSecs = endTimeUnix - startTimeUnix;
  const logQuery = buildQuery(logsState);

  const stepMs = bucketSecs * 1000;

  return `
subscription {
  getLogMetricsTimeSeriesStream(
    durationMs: ${durationSecs * 1000}
    lookBackMs: ${stepMs}
    stepMs: ${stepMs}
    ${logQuery !== '{}' ? `logQuery: ${logQuery},` : ''}
    rangeAggregate: "count_over_time"
    vectorAggregate: "sum"
    vectorAggregateGrouping: {
      groups: ["level"]
    }
    timestamp: "${endTime.format()}",
  ) {
    points {
      ts
      value
    }
    tags
  }
}`;
};

type Props = {
  getWorkbooksRequest: ReturnType<typeof useRequest>;
  user: User;
};

const Logs = ({ getWorkbooksRequest, user }: Props): ReactElement => {
  const logsPageState = useLogsPageStateContext();
  const {
    customerFilter,
    dependenciesForWriteStateToUrl,
    dateState,
    queriesState,
    setCustomerFilter,
    writeStateToUrl,
  } = logsPageState;

  useEffect(() => {
    writeStateToUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependenciesForWriteStateToUrl);

  const fingerprintQueryState = useState<FingerprintQueryProps>({
    countOf: '*',
    groupBy: [`Core${delimiter}source${delimiter}string`],
    limit: 100,
    sortBy: '',
    sortOrder: 'Desc',
  });

  const { value: logsDatePickerMaxQuickRange } = useFeatureFlag(
    'logsDatePickerMaxQuickRange',
    'now-7d',
  );
  const {
    value: isCustomerFilteringEnabled,
    loading: isCustomerFilteringConfigLoading,
  } = useFeatureFlag('isCustomerFilteringEnabled', false);
  const getFacetNamesRequest = useRequest((args) => {
    return getLogsFavouriteFacetsByCursor(args);
  });

  const getServiceGroupLabelRequest = useRequest(getServiceGroupLabels);
  const getServiceGroupValuesRequest = useRequest(
    getCustomerLabelValuesForLogs,
  );

  const logsWorkbooksState = useLogsWorkbooksState();
  const { currentWorkbook, isReady, setWorkbooks } = logsWorkbooksState;
  const labelsState = useState<LabelsProps>();
  const { tab } = useParams();
  const isAnalyticsView = useMemo(() => {
    return isTabAnalytics(tab);
  }, [tab]);

  const logsAnalytics = useLogsAnalyticsChart({
    customerFilter,
    date: dateState[0],
    labels: labelsState[0],
    tab,
    queriesState,
  });

  const {
    activeQueryIndex,
    activeSidebarLogsState,
    logsState,
    refreshFacetGroups,
    setRefreshFacetGroups,
  } = useLogsState({
    customerFilter,
    dateState,
    isAnalyticsView,
    logsAnalytics,
  });
  const leftSidebarState = useLeftSidebarState('logs');
  const queryScheduler = useQueryScheduler(leftSidebarState);
  const showHistoryToggle = useToggle();
  const showTimelineToggle = useLocalStorageToggle(
    'show-timeline-toggle',
    true,
  );

  const getLogStackedBarCountsUsingMetricsRequest = useSubscriptionRequest({
    queryBuilder,
    merge: (prevResult, update, callArgs) => {
      const { bucketSecs } = callArgs[0];
      const anchorTs = prevResult.length ? prevResult[0].bucketStart : null;
      const stepMs = bucketSecs * 1000;
      return [
        ...prevResult,
        ...formatSeriesToLogCountsWithStepSize({
          anchorTs,
          stepMs,
          timeseries: update,
        }),
      ];
    },
    key: 'getLogMetricsTimeSeriesStream',
    initialState: [],
  });

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const bindKeyHandlersRef = useRef<VoidFunction>();
  const logsLiveTail = useLogsLiveTail();
  const tabFetchedRef = useRef<Record<string, number>>({});
  const getFpListRequest = useRequest(getFpList);
  const logsTable = useLogsTable();

  const isLiveTailEnabled = logsLiveTail?.isEnabled;
  const logsRef = useRef(logsTable.logs);

  logsRef.current = isLiveTailEnabled
    ? logsLiveTail.liveTailLogs
    : logsTable.logs;

  const tableOptions = useTableOptions();
  const [hoveredLogDateUnix, setHoveredLogDateUnix] = useState(null);

  const customColumnsState = useKeyExistsState({
    urlStateKey: 'customColumns',
  });

  const columns = useMemo(
    () => getColumns({ customColumnsState, logsState }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customColumnsState.state],
  );

  useEffect(() => {
    const currentParams = localStorage.getItem('logsUrlParams') || '';

    const currentUrlParamsInLocalStorage = new URLSearchParams(currentParams);
    currentUrlParamsInLocalStorage.set(
      'customColumns',
      JSON.stringify(customColumnsState.state),
    );

    localStorage.setItem(
      'logsUrlParams',
      currentUrlParamsInLocalStorage.toString(),
    );
  }, [customColumnsState.state]);

  const columnsState = useColumnsState({
    columns,
    customColumnsState,
    initialState: {
      resizedWidths: {},
      selectedColumns: {
        [MESSAGE]: 1,
        [SOURCE]: 1,
        [TIMESTAMP]: 1,
      },
    },
    key: 'logs-table',
  });

  const setKeyExists = (key: string) => {
    customColumnsState.setKeyExists(key);
    columnsState.setSelectedColumnByKey(key);
  };

  const selectedLogFromContext = currentWorkbook.selectedLogFromContext || null;
  const [selectedLog, setSelectedLog] = useUrlState<SelectedLog>(
    'selectedLog',
    null,
  );

  const clearHoveredLogDateUnix = () => {
    setHoveredLogDateUnix(null);
  };

  const overriddenCustomColumnsState = {
    ...customColumnsState,
    setKeyExists,
  };

  useEffect(() => {
    logsLiveTail.init(logsState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    setWorkbooks([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { next, prev } = useMemo(
    () => getLogsPrevAndNextHandlers({ logsRef, setSelectedLog }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (!isTabAnalytics(tab)) {
      const isURLCleanupNeeded =
        searchParams.has('LogsMetricsQueries') ||
        searchParams.has('LogsMetricsFormulas');
      if (isURLCleanupNeeded) {
        searchParams.delete('LogsMetricsQueries');
        searchParams.delete('LogsMetricsFormulas');
        navigate(`?${searchParams.toString()}`, { replace: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  useEffect(() => {
    if (tab !== 'fingerprints') {
      if (getFpListRequest?.result?.length) {
        getFpListRequest.clear();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    logsState.customerFilter,
    logsState.date,
    logsState.filterByFacets,
    logsState.keyExists,
    logsState.searchTerms,
    logsState.selectedFacetRanges,
    logsState.selectedFacetValues,
  ]);

  const { analyticsChart, chartWidth, logsMetricsQueryState } = logsAnalytics;
  const {
    formulas,
    loadMultipleLogsChartData,
    loadInstantMultipleLogsChartData,
    queries,
  } = logsMetricsQueryState;

  const latestQueries = useLatest(queries);

  const { activeVisualization } = analyticsChart;

  const handleGenerateChart = (queries: LogsMetricQueryProps[]) => {
    if (activeVisualization === 'timeseries') {
      loadMultipleLogsChartData({ chartWidth, queries, formulas });
    } else {
      loadInstantMultipleLogsChartData({
        chartWidth,
        queries,
        formulas,
        returnFormat: activeVisualization,
      });
    }
  };

  const {
    date,
    filterOrExcludeByFingerprint,
    filterByFacets,
    keyExists,
    searchTerms,
    selectedFacetValues,
    setDate,
  } = logsState;

  const activeFormulasChangeForChart = useMemo(() => {
    const formulasMap = formulas.reduce(
      (acc, formula) => {
        acc[formula.queryKey] = formula.isActive;
        return acc;
      },
      {} as Record<string, boolean>,
    );
    return JSON.stringify(formulasMap);
  }, [formulas]);

  useDebouncedEffect(
    () => {
      if (isAnalyticsView) {
        handleGenerateChart(latestQueries.current);
      }
    },
    {
      timeout: 300,
      ignoreInitialCall: false,
    },
    [activeVisualization, customerFilter, date, isAnalyticsView],
  );

  const onDateChange = (nextDate: DateSelection) => {
    logsLiveTail.stopLiveTail();
    setDate(nextDate);
  };

  const { enableLiveTail, isEnabled, isPlaying, toggleLiveTail } = logsLiveTail;
  const onToggleLiveTailClick = () => {
    toggleLiveTail({
      date,
      filterOrExcludeByFingerprint,
      filterByFacets,
      keyExists,
      searchTerms,
      selectedFacetValues,
    });
  };

  const logPeriodOptions = useMemo(() => {
    return generateLogPeriodOptions(logsDatePickerMaxQuickRange);
  }, [logsDatePickerMaxQuickRange]);

  useEffect(() => {
    if (isCustomerFilteringEnabled) {
      getServiceGroupLabelRequest.call();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCustomerFilteringEnabled]);

  const selectedLabel = useMemo(() => {
    if (
      getServiceGroupLabelRequest.result &&
      getServiceGroupLabelRequest.result.length
    ) {
      const firstLabel = getServiceGroupLabelRequest.result[0];
      return firstLabel;
    }
    return null;
  }, [getServiceGroupLabelRequest.result]);

  useDebouncedEffect(
    () => {
      if (selectedLabel) {
        getServiceGroupValuesRequest.call({ label: selectedLabel });
      }
    },
    50,
    [selectedLabel],
  );

  const isCustomerFilterSelected = Boolean(customerFilter?.value);

  const showLogsLandingPage =
    !isCustomerFilteringEnabled || isCustomerFilterSelected;

  if (isCustomerFilteringConfigLoading) {
    return <Loader className="h-full" isLoading={true} />;
  }

  return (
    <div
      className={classnames({
        logs: true,
        'logs--live-tail': isLiveTailEnabled,
      })}
    >
      {isReady ? (
        <div className="logs__main">
          {!isLiveTailEnabled && (
            <LeftSidebar leftSidebarState={leftSidebarState}>
              {isCustomerFilteringEnabled && (
                <CustomerFilter
                  customerFilter={customerFilter}
                  filterLabel={selectedLabel}
                  valueOptions={getServiceGroupValuesRequest.result || []}
                  setCustomerFilter={setCustomerFilter}
                />
              )}
              {showLogsLandingPage && (
                <LogsSidebar
                  getLogStackedBarCountsUsingMetricsRequest={
                    getLogStackedBarCountsUsingMetricsRequest
                  }
                  isAnalyticsView={isAnalyticsView}
                  labelsState={labelsState}
                  logsState={activeSidebarLogsState}
                  setRefreshFacetGroups={setRefreshFacetGroups}
                  refreshFacetGroups={refreshFacetGroups}
                  queryScheduler={queryScheduler}
                  logsAnalytics={logsAnalytics}
                />
              )}
            </LeftSidebar>
          )}

          {showLogsLandingPage ? (
            <div className="logs__content">
              {!isLiveTailEnabled ? (
                <DateWarningAlert className="logs__banner" date={date} />
              ) : null}
              <div className="logs__search">
                <div
                  className="logs__search__top"
                  data-testid="logs-search-top"
                >
                  <div className="flex-1">
                    {isAnalyticsView ? (
                      <div className="flex">
                        <div className="mt-2">
                          {!leftSidebarState.width ? (
                            <ShowSidebarTooltipButton
                              onClick={leftSidebarState.show}
                            />
                          ) : null}
                        </div>

                        <LogsAnalyticsQueryBuilder
                          activeVisualization={activeVisualization}
                          logsMetricsQueryState={logsMetricsQueryState}
                          logsState={logsState}
                        />
                      </div>
                    ) : (
                      <LogsSearch
                        leftSidebarState={leftSidebarState}
                        logsLiveTail={logsLiveTail}
                        logsState={logsState}
                        originalGetFacetNamesRequest={getFacetNamesRequest}
                      />
                    )}
                  </div>
                  <Datepicker
                    className="logs__search__datepicker"
                    hasStartedLiveTail={isEnabled}
                    onChange={onDateChange}
                    startLiveTail={enableLiveTail}
                    value={date}
                    quickRangeOptions={logPeriodOptions}
                  />
                  {isEnabled ? (
                    <button
                      className="logs__search__live-tail-button"
                      onClick={onToggleLiveTailClick}
                      data-testid="live-tail-button"
                    >
                      {isPlaying ? (
                        <Pause
                          className="logs__search__live-tail-button__icon"
                          size={16}
                        />
                      ) : (
                        <Play
                          className="logs__search__live-tail-button__icon"
                          size={16}
                        />
                      )}
                    </button>
                  ) : null}
                  {!isEnabled ? (
                    <LogsSearchRefreshButton logsState={logsState} />
                  ) : null}
                </div>
              </div>
              <LogsTabsContainer
                showTimelineToggle={showTimelineToggle}
                isAnalyticsView={isAnalyticsView}
              />
              {!logsLiveTail.isEnabled ? (
                <>
                  <LogsTimeline
                    activeQueryIndex={activeQueryIndex}
                    getLogStackedBarCountsUsingMetricsRequest={
                      getLogStackedBarCountsUsingMetricsRequest
                    }
                    hideTimeline={isAnalyticsView}
                    hoveredLogDateUnix={hoveredLogDateUnix}
                    logsState={activeSidebarLogsState}
                    queryScheduler={queryScheduler}
                    selectedLog={selectedLog}
                    showTimelineToggle={showTimelineToggle}
                  />
                  {isAnalyticsView ? null : (
                    <LogsQueryTimer queryScheduler={queryScheduler} />
                  )}
                </>
              ) : null}
              <div
                className="logs__main__content"
                data-testid="logs-main-content"
              >
                {tab === undefined ? (
                  <LogsTable
                    customColumnsState={overriddenCustomColumnsState}
                    columnsState={columnsState}
                    clearHoveredLogDateUnix={clearHoveredLogDateUnix}
                    getLogStackedBarCountsUsingMetricsRequest={
                      getLogStackedBarCountsUsingMetricsRequest
                    }
                    getWorkbooksRequest={getWorkbooksRequest}
                    logsLiveTail={logsLiveTail}
                    logsState={logsState}
                    logsTable={logsTable}
                    logsWorkbooksState={logsWorkbooksState}
                    queryScheduler={queryScheduler}
                    selectedLog={selectedLog}
                    selectedLogFromContext={selectedLogFromContext}
                    setHoveredLogDateUnix={setHoveredLogDateUnix}
                    setSelectedLog={setSelectedLog}
                    bindKeyHandlersRef={bindKeyHandlersRef}
                    tabFetchedRef={tabFetchedRef}
                    tableOptions={tableOptions}
                  />
                ) : null}
                {tab === 'fingerprints' ? (
                  <LogsFingerprintsList
                    customColumnsState={overriddenCustomColumnsState}
                    columnsState={columnsState}
                    getFacetNamesRequest={getFacetNamesRequest}
                    getFpListRequest={getFpListRequest}
                    getLogStackedBarCountsUsingMetricsRequest={
                      getLogStackedBarCountsUsingMetricsRequest
                    }
                    fingerprintQueryState={fingerprintQueryState}
                    labels={labelsState[0]}
                    logsState={logsState}
                    queryScheduler={queryScheduler}
                    tabFetchedRef={tabFetchedRef}
                  />
                ) : null}
                {tab === 'transactions' ? (
                  <LogsTransactions
                    getFacetNamesRequest={getFacetNamesRequest}
                    logsState={logsState}
                  />
                ) : null}
                {isAnalyticsView ? (
                  <LogsAnalytics
                    getLogStackedBarCountsUsingMetricsRequest={
                      getLogStackedBarCountsUsingMetricsRequest
                    }
                    logsState={activeSidebarLogsState}
                    queryScheduler={queryScheduler}
                    tabFetchedRef={tabFetchedRef}
                    logsAnalytics={logsAnalytics}
                  />
                ) : null}
              </div>
            </div>
          ) : (
            <div className="flex flex-col height-100-per w-full">
              {leftSidebarState.width === 0 ? (
                <div className="flex justify-start">
                  <ShowSidebarTooltipButton
                    className="customer-filter__show-sidebar"
                    onClick={leftSidebarState.show}
                  />
                  <div />
                </div>
              ) : null}
              <div className="flex-1 flex items-center justify-center">
                <div className="facet-picker__values__placeholder italic text-2xl">
                  Select Service Group Filter To View the Logs
                </div>
              </div>
            </div>
          )}
        </div>
      ) : null}
      {showHistoryToggle.value && currentWorkbook ? (
        <RightSidebar
          className="logs__workbook-history"
          close={showHistoryToggle.off}
          title="History"
        >
          <LogsWorkbookHistory
            logsWorkbooksState={logsWorkbooksState}
            showHistoryToggle={showHistoryToggle}
            user={user}
          />
        </RightSidebar>
      ) : null}
      {selectedLog && selectedLog.log ? (
        <RightSidebar
          className="logs__right-drawer"
          close={() => {
            const bindKeyHandlers = bindKeyHandlersRef.current;
            if (bindKeyHandlers) {
              bindKeyHandlers();
            }
            setSelectedLog(null);
          }}
          title={
            <LogsSelectedLogTitle
              logEvent={selectedLog.log}
              customColumnsState={overriddenCustomColumnsState}
              logsState={logsState}
            />
          }
          prev={prev}
          next={next}
        >
          <LogsSelectedLog
            customColumnsState={overriddenCustomColumnsState}
            logs={logsTable.logs}
            logsState={logsState}
            logsWorkbooksState={logsWorkbooksState}
            selectedLog={selectedLog}
            setRefreshFacetGroups={setRefreshFacetGroups}
            prev={prev}
            next={next}
          />
        </RightSidebar>
      ) : null}
      {<ProductTour />}
    </div>
  );
};

export default Logs;
