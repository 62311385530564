import {
  Loader,
  NodeSizeBasedOn,
  ServiceMap,
  ServiceServiceMapNodeLabel,
  ServiceWithLabels,
} from 'components';
import { calculateDiameterOfNode } from 'components/ServiceMap/utils';
import { useRequest, useSpanTypeFilters } from 'hooks';
import { blackListedLabelsBitmap } from 'kfuse-constants';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { IoIosWarning } from 'react-icons/io';
import { MarkerType } from 'reactflow';
import { queryRange } from 'requests';
import {
  DateSelection,
  PrometheusDataset,
  SelectedFacetValuesByName,
  Service,
} from 'types';
import {
  buildPromQLFilterFromSelectedFacetValuesByName,
  getApmSumBy,
  getSelectedFacetValueByNameWithCustomerFilters,
} from 'utils';
import TracesServiceMapLinkTooltip from './TracesServiceMapLinkTooltip';
import TracesServiceMapNodeTooltip from './TracesServiceMapNodeTooltip';
import { getTimeParameter } from './utils';
import useDebouncedEffect from 'use-debounced-effect';

const flattenResults = (results) =>
  results.reduce((arr, result) => [...arr, ...result], []);

const formatRequestsByServiceName = () => (result: PrometheusDataset[]) => {
  const requestsByServiceName: { [key: string]: number } = {};

  result.forEach((dataset) => {
    const { metric, value } = dataset;
    const { service_hash } = metric;

    if (value && value.length > 1 && service_hash) {
      requestsByServiceName[service_hash] = Number(value[1]);
    }
  });
  return requestsByServiceName;
};

const formatMaxLatencyByServiceName = () => (result: PrometheusDataset[]) => {
  const maxLatencyByServiceName: { [key: string]: number } = {};

  result.forEach((dataset) => {
    const { metric, value } = dataset;
    const { service_hash } = metric;

    if (value && value.length > 1 && service_hash) {
      maxLatencyByServiceName[service_hash] = Number(value[1]);
    }
  });

  return maxLatencyByServiceName;
};

const formatDataset = () => (result: PrometheusDataset[]) => {
  const edgeById: { [id: string]: any } = {};
  const nodeById: { [serviceName: string]: any } = {};
  result.forEach((dataset) => {
    const { metric } = dataset;
    const { client_service_name, service_name } = metric;

    const client_service_hash =
      metric.client_service_hash === 'UNKNOWN'
        ? `UNKNOWN-client-${client_service_name}`
        : metric.client_service_hash;

    const service_hash =
      metric.service_hash === 'UNKNOWN'
        ? `UNKNOWN-server-${service_name}`
        : metric.service_hash;

    if (client_service_hash) {
      if (!nodeById[client_service_hash]) {
        nodeById[client_service_hash] = {
          id: client_service_hash,
          data: {
            label: client_service_hash,
            serviceName: metric.client_service_name,
          },
        };
      }
    }

    if (service_hash) {
      if (!nodeById[service_hash]) {
        nodeById[service_hash] = {
          id: service_hash,
          data: {
            label: service_hash,
            serviceName: metric.service_name,
          },
        };
      }
    }

    if (client_service_hash && service_hash) {
      const sourceId = client_service_hash;

      const targetId = service_hash;

      if (sourceId !== targetId) {
        const edgeIds = [sourceId, targetId];
        const edgeId = edgeIds.join('->');
        if (!edgeById[edgeId]) {
          edgeById[edgeId] = {
            source: sourceId,
            target: targetId,
            markerEnd: {
              type: MarkerType.ArrowClosed,
              width: 20,
              height: 20,
              color: '#FF0072',
            },
            data: {
              clientServiceName: client_service_name,
              clientServiceHash: client_service_hash,
              serviceName: service_name,
              serviceHash: service_hash,
            },
          };
        } else {
          const edge = edgeById[edgeId];
          const { source } = edge;

          const targetId = client_service_hash;
          if (source !== targetId) {
            edge.markerStart = {
              type: MarkerType.ArrowClosed,
              width: 20,
              height: 20,
              color: '#FF0072',
            };
          }
        }
      }
    }
  });

  const uniqueEdgeIdBitmap: Record<string, number> = {};
  const edges: any[] = [];

  Object.keys(edgeById).forEach((edgeId) => {
    const [sourceId, targetId] = edgeId.split('->');
    const uniqueEdgeId = [sourceId, targetId].sort().join('->');

    if (!uniqueEdgeIdBitmap[uniqueEdgeId]) {
      uniqueEdgeIdBitmap[uniqueEdgeId] = 1;

      const inverseEdgeId = [targetId, sourceId].join('->');

      const edge = edgeById[edgeId];
      const hasInverse = Boolean(edgeById[inverseEdgeId]);
      const inverseMarkerKey = edge.markerStart ? 'markerEnd' : 'markerStart';

      edges.push({
        ...edge,
        ...(hasInverse
          ? {
              [inverseMarkerKey]: {
                type: MarkerType.ArrowClosed,
                width: 20,
                height: 20,
                color: '#FF0072',
              },
            }
          : {}),
        data: {
          ...edge.data,
          hasInverse,
        },
      });
    }
  });

  const nodes = Object.values(nodeById);

  return { edges, nodes };
};

const formatErrorsByServiceName = () => (result: PrometheusDataset[]) => {
  const errorsByServiceName: { [key: string]: number } = {};

  result.forEach((dataset) => {
    const { metric, value } = dataset;
    const { service_hash } = metric;

    if (service_hash) {
      errorsByServiceName[service_hash] = Number(value[1]);
    }
  });

  return errorsByServiceName;
};

const getClientSelectedFacetValuesByName = (
  selectedFacetValuesByName: SelectedFacetValuesByName,
) =>
  Object.keys(selectedFacetValuesByName).reduce(
    (obj, name) => ({
      ...obj,
      [!blackListedLabelsBitmap[name] ? `client_${name}` : name]:
        selectedFacetValuesByName[name],
    }),
    {},
  );

const getNodesAndEdges = ({
  colorsByServiceHash,
  edges,
  errorsByServiceName,
  maxLatencyByServiceName,
  nodes,
  nodeSizeBasedOn,
  requestsByServiceName,
  serviceByHash,
  selectedFacetValuesByName,
}: {
  colorsByServiceHash: { [key: string]: string };
  edges: any[];
  errorsByServiceName: Record<string, number>;
  maxLatencyByServiceName: Record<string, number>;
  nodes: any[];
  nodeSizeBasedOn: NodeSizeBasedOn;
  requestsByServiceName: Record<string, number>;
  serviceByHash: Record<string, Service>;
  selectedFacetValuesByName: SelectedFacetValuesByName;
}) => {
  const getHashToUse = () => {
    switch (nodeSizeBasedOn) {
      case 'errors':
        return errorsByServiceName;
      case 'maxlatency':
        return maxLatencyByServiceName;
      case 'requests':
        return requestsByServiceName;
      default:
        return requestsByServiceName;
    }
  };
  const max = Math.max(...Object.values(requestsByServiceName));
  const maxValue = Math.max(...Object.values(getHashToUse()), 0);

  const minValue = Math.min(...Object.values(getHashToUse()), 0);

  const minValueToUse =
    nodes.length !== Object.keys(getHashToUse()).length ? 0 : minValue;

  return {
    edges: edges.map((edge) => {
      const { clientServiceHash, clientServiceName, serviceHash, serviceName } =
        edge.data;

      const clientService = serviceByHash[clientServiceHash];
      const service = serviceByHash[serviceHash];

      return {
        ...edge,
        data: {
          ...edge.data,
          clientServiceHash,
          clientServiceName,
          clientServiceDistinctLabels: clientService?.distinctLabels || {},
          serviceHash,
          serviceName,
          serviceDistinctLabels: service?.distinctLabels || {},
        },
      };
    }),
    nodes: nodes.map((node) => {
      const requests = requestsByServiceName[node.id] || 0;
      const errors = errorsByServiceName[node.id] || 0;
      const maxlatency = maxLatencyByServiceName[node.id] || 0;
      const outerRingSize = max ? Math.round((requests / max) * 10) : 0;
      const getCountToUse = () => {
        switch (nodeSizeBasedOn) {
          case 'errors':
            return errors;
          case 'maxlatency':
            return maxlatency;
          case 'requests':
            return requests;
          default:
            return requests;
        }
      };
      const nodeDiameter = calculateDiameterOfNode({
        count: getCountToUse(),
        minValue: minValueToUse,
        maxValue: maxValue,
      });

      const service = serviceByHash[node.id];
      const name = service?.name || node.data.serviceName;
      const distinctLabels = service?.distinctLabels || {};
      const labels = service?.labels || {};

      return {
        ...node,
        data: {
          ...node.data,
          color: colorsByServiceHash[node.id],
          hasError: Boolean(errorsByServiceName[node.id]),
          distinctLabels,
          label: name,
          optionLabel: (
            <ServiceWithLabels
              color={colorsByServiceHash[node.id]}
              name={name}
              distinctLabels={distinctLabels}
              labels={labels}
            />
          ),
          renderLabel: ({ search }) => (
            <ServiceServiceMapNodeLabel
              distinctLabels={distinctLabels}
              labels={labels}
              name={name}
              search={search}
              serviceByHash={serviceByHash}
              serviceHash={node.id}
              selectedFacetValuesByName={selectedFacetValuesByName}
              showLink={Boolean(service)}
            />
          ),
          outerRingSize,
          nodeDiameter,
        },
        searchValue: service ? JSON.stringify(service).toLowerCase() : node.id,
      };
    }),
    maxNodeSize: maxValue,
    minNodeSize: minValueToUse,
  };
};

type ServiceMapQueryArgs = {
  date: DateSelection;
  selectedFacetValuesByName: SelectedFacetValuesByName;
  spanTypeFilters?: ReturnType<typeof useSpanTypeFilters>;
};

const serviceMapQuery = ({
  date,
  selectedFacetValuesByName,
  spanTypeFilters,
}: ServiceMapQueryArgs) => {
  const timeParameter = getTimeParameter(date);
  const promqlFilter = buildPromQLFilterFromSelectedFacetValuesByName({
    selectedFacetValuesByName,
    spanTypeFilter: spanTypeFilters?.state,
  });

  const sumBy = getApmSumBy([
    'service_hash',
    'client_service_hash',
    'service_name',
    'client_service_name',
  ]);

  return `sum by (${sumBy}) (rate(edge_latency_count${promqlFilter}[${timeParameter}]))`;
};

type Props = {
  colorsByServiceHash: { [key: string]: string };
  customerFilter?: { key: string; value: string };
  date: DateSelection;
  selectedFacetValuesByName: SelectedFacetValuesByName;
  serviceByHash: Record<string, Service>;
};

const TracesServiceMap = ({
  colorsByServiceHash,
  customerFilter,
  date,
  selectedFacetValuesByName,
  serviceByHash,
}: Props) => {
  const containerRef = useRef(null);
  const spanTypeFilters = useSpanTypeFilters();

  const [nodeSizeBasedOn, setNodeSizeBasedOn] =
    useState<NodeSizeBasedOn>('requests');

  const [error, setError] = useState({
    getRequestsByServiceName: null,
    getErrorsByServiceName: null,
    getMaxLatencyByServiceName: null,
    getQueryRange: null,
  });

  const requestsByServiceNameRequest = useRequest(
    (args) => {
      const { selectedFacetValuesByName } = args;
      const timeDuration = getTimeParameter(args.date);
      const promqlFilter = buildPromQLFilterFromSelectedFacetValuesByName({
        selectedFacetValuesByName: {
          ...selectedFacetValuesByName,
          kf_source: {
            ...(args.selectedFacetValuesByName.kf_source || { apm: 1 }),
          },
        },
      });

      const sumBy = getApmSumBy(['service_hash']);

      return Promise.all([
        queryRange({
          date: args.date,
          query: `sum by (${sumBy}) (rate(edge_latency_count${promqlFilter}[${timeDuration}]))`,
          instant: true,
        }),
      ])
        .then(flattenResults)
        .then(formatRequestsByServiceName());
    },
    true,
    true,
  );

  const errorsByServiceNameRequest = useRequest(
    (args) => {
      const timeDuration = getTimeParameter(args.date);
      const promqlFilter = buildPromQLFilterFromSelectedFacetValuesByName({
        selectedFacetValuesByName: {
          ...args.selectedFacetValuesByName,
          kf_source: {
            ...(args.selectedFacetValuesByName.kf_source || { apm: 1 }),
          },
          error: {
            true: 1,
          },
        },
      });

      const sumBy = getApmSumBy(['service_hash']);
      return queryRange({
        date: args.date,
        instant: true,
        query: `sum by (${sumBy}) (rate(edge_latency_count${promqlFilter}[${timeDuration}]))`,
      }).then(formatErrorsByServiceName());
    },
    true,
    true,
  );

  const maxLatencyByServiceNameRequest = useRequest(
    (args) => {
      const timeDuration = getTimeParameter(args.date);
      const promqlFilter = buildPromQLFilterFromSelectedFacetValuesByName({
        selectedFacetValuesByName: {
          ...args.selectedFacetValuesByName,
          kf_source: {
            ...(args.selectedFacetValuesByName.kf_source || { apm: 1 }),
          },
        },
      });

      const sumBy = getApmSumBy(['service_hash']);
      return queryRange({
        date: args.date,
        instant: true,
        query: `max by (${sumBy}) (max_over_time(edge_latency_max${promqlFilter}[${timeDuration}]))`,
      }).then(formatMaxLatencyByServiceName());
    },
    true,
    true,
  );

  const queryRangeRequest = useRequest(
    async (args) => {
      const { selectedFacetValuesByName } = args;
      const clientSelectedFacetValuesByName =
        getClientSelectedFacetValuesByName(selectedFacetValuesByName);

      return Promise.all([
        queryRange({
          date: args.date,
          instant: true,
          query: serviceMapQuery({
            date: args.date,
            selectedFacetValuesByName: args.selectedFacetValuesByName,
            spanTypeFilters,
          }),
        }),
        ...(Object.keys(clientSelectedFacetValuesByName).length
          ? [
              queryRange({
                date: args.date,
                instant: true,
                query: serviceMapQuery({
                  date: args.date,
                  selectedFacetValuesByName: clientSelectedFacetValuesByName,
                  spanTypeFilters,
                }),
              }),
            ]
          : []),
      ])
        .then(flattenResults)
        .then(formatDataset());
    },
    true,
    true,
  );

  const requestsByServiceName = useMemo(
    () => requestsByServiceNameRequest.result || {},
    [requestsByServiceNameRequest.result],
  );

  const maxLatencyByServiceName = useMemo(
    () => maxLatencyByServiceNameRequest.result || {},
    [maxLatencyByServiceNameRequest.result],
  );

  const { edges, nodes, maxNodeSize, minNodeSize } = useMemo(
    () =>
      getNodesAndEdges({
        colorsByServiceHash,
        errorsByServiceName: errorsByServiceNameRequest.result || {},
        maxLatencyByServiceName,
        nodes: queryRangeRequest.result?.nodes || [],
        edges: queryRangeRequest.result?.edges || [],
        requestsByServiceName,
        selectedFacetValuesByName,
        serviceByHash,
        nodeSizeBasedOn,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      colorsByServiceHash,
      errorsByServiceNameRequest.result,
      maxLatencyByServiceName,
      nodeSizeBasedOn,
      queryRangeRequest.result,
      requestsByServiceName,
      selectedFacetValuesByName,
      serviceByHash,
    ],
  );

  useDebouncedEffect(
    () => {
      const selectedFacetsWithCustomerFilters =
        getSelectedFacetValueByNameWithCustomerFilters({
          customerFilter,
          selectedFacetValuesByName,
        });
      requestsByServiceNameRequest.call({
        date,
        selectedFacetValuesByName: selectedFacetsWithCustomerFilters,
      });
      queryRangeRequest.call({
        date,
        selectedFacetValuesByName: selectedFacetsWithCustomerFilters,
      });
      // spanTypeByServiceNameRequest.call({ date });
      errorsByServiceNameRequest.call({
        date,
        selectedFacetValuesByName: selectedFacetsWithCustomerFilters,
      });
      maxLatencyByServiceNameRequest.call({
        date,
        selectedFacetValuesByName: selectedFacetsWithCustomerFilters,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    {
      timeout: 50,
      ignoreInitialCall: false,
    },
    [customerFilter, date, selectedFacetValuesByName, spanTypeFilters?.state],
  );

  const upstreamBitmap = useMemo(() => {
    const edgeTargetBitmap = edges.reduce((obj, edge) => {
      return {
        ...obj,
        [edge.target]: 1,
      };
    }, {});

    return nodes
      .filter((node) => !edgeTargetBitmap[node.id])
      .reduce((obj, node) => ({ ...obj, [node.id]: 1 }), {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodes, edges]);

  const getSearchedValue = useCallback(
    (value) =>
      serviceByHash[value]
        ? JSON.stringify(serviceByHash[value]).toLowerCase()
        : value,
    [serviceByHash],
  );

  const handleClearBEFilters = () => {
    spanTypeFilters.clear();
  };

  const legendProps = {
    maxNodeSize,
    minNodeSize,
    nodeSizeBasedOn,
    setNodeSizeBasedOn,
  };

  useEffect(() => {
    if (requestsByServiceNameRequest.error) {
      setError((prevError) => ({
        ...prevError,
        getRequestsByServiceName: {
          message: 'Failed to fetch requests by service name',
          status_code: 401,
        },
      }));
    }
  }, [requestsByServiceNameRequest.error]);

  useEffect(() => {
    if (errorsByServiceNameRequest.error) {
      setError((prevError) => ({
        ...prevError,
        getErrorsByServiceName: {
          message: 'Failed to fetch errors by service name',
          status_code: 401,
        },
      }));
    }
  }, [errorsByServiceNameRequest.error]);

  useEffect(() => {
    if (maxLatencyByServiceNameRequest.error) {
      setError((prevError) => ({
        ...prevError,
        getMaxLatencyByServiceName: {
          message: 'Failed to fetch max latency by service name',
          status_code: 401,
        },
      }));
    }
  }, [maxLatencyByServiceNameRequest.error]);

  useEffect(() => {
    if (queryRangeRequest.error) {
      setError((prevError) => ({
        ...prevError,
        getQueryRange: {
          message: 'Failed to fetch query range result',
          status_code: 401,
        },
      }));
    }
  }, [queryRangeRequest.error]);

  return (
    <div className="traces__service-map" data-testid="service-map-overlay">
      {(error.getRequestsByServiceName ||
        error.getErrorsByServiceName ||
        error.getMaxLatencyByServiceName ||
        error.getQueryRange) && (
        <div className="flex gap-[4px] w-full justify-end pr-[14px]">
          <IoIosWarning
            className="overlay-message__icon-and-message__icon"
            size={16}
          />
          <div className="text-red-500">Service map request failed</div>
        </div>
      )}

      <Loader
        className="traces__service-map__loader"
        isLoading={queryRangeRequest.isLoading}
        ref={containerRef}
      >
        <ServiceMap
          areBEFiltersSelected={spanTypeFilters.isShowDatabasesChecked}
          customBEFilters={[]}
          getSearchedValue={getSearchedValue}
          handleClearBEFilters={handleClearBEFilters}
          initialEdges={edges}
          initialNodes={nodes}
          renderNodeTooltip={(id, node) => {
            return (
              <TracesServiceMapNodeTooltip
                customerFilter={customerFilter}
                date={date}
                isUpstream={upstreamBitmap[id]}
                name={serviceByHash[id]?.name || node.serviceName || id}
                serviceHash={id.startsWith('UNKNOWN') ? 'UNKNOWN' : id}
                serviceName={node.serviceName}
                selectedFacetValuesByName={selectedFacetValuesByName}
              />
            );
          }}
          renderEdgeTooltip={(edge) => (
            <TracesServiceMapLinkTooltip date={date} edge={edge} />
          )}
          legendProps={legendProps}
        />
      </Loader>
    </div>
  );
};

export default TracesServiceMap;
