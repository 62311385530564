import { AnalyticsChart } from 'components';
import { useSearches, useSearchFormulas, useTracesState } from 'hooks';
import { size } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Service } from 'types';
import { formatDurationNs, getIsHcFacetName } from 'utils';
import useTracesTimeseries from './useTracesTimeseries';
import { useAnalyticsChartOverlayMessage } from 'hooks';
import { useFeatureFlag } from 'configcat-react';

type Props = {
  customerFilter: { key: string; value: string };
  isLiveTailEnabled?: boolean;
  searches: ReturnType<typeof useSearches>;
  serviceByHash: Record<string, Service>;
  searchesFormulas?: ReturnType<typeof useSearchFormulas>;
  tracesState: ReturnType<typeof useTracesState>;
  tracesTab: string;
};

const TracesTimeseries = ({
  customerFilter,
  isLiveTailEnabled,
  searches,
  searchesFormulas,
  serviceByHash,
  tracesState,
  tracesTab,
}: Props) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const tracesTimeseries = useTracesTimeseries({
    customerFilter,
    searches,
    searchesFormulas,
    serviceByHash,
    tracesState,
    tracesTab,
  });

  const {
    analyticsChart,
    analyticsData,
    aggregateTimeSeriesMultipleV2Request,
    groupBysForTable,
    openExportToDashboardModal,
    openSaveMetricModal,
    onViewFullscreen,
  } = tracesTimeseries;

  const { dateState } = tracesState;
  const { value: enableTraceSaveMetrics } = useFeatureFlag(
    'enableTraceSaveMetrics',
    false,
  );
  const [date, setDate] = dateState;

  const saveButtonDisabledReasonIfDisabled: string | null = useMemo(() => {
    const hcFacetKeys = Object.keys(
      tracesState?.selectedHcFacetValuesByNameState?.state || {},
    ).filter((name) => getIsHcFacetName(name));

    return size(hcFacetKeys) > 0
      ? 'Save not allowed when filter includes a high cardinality attribute.'
      : null;
  }, [tracesState?.selectedHcFacetValuesByNameState?.state]);

  useEffect(() => {
    const searchParamsString = searchParams.toString();
    if (isLiveTailEnabled) {
      navigate(
        `/apm/traces/list${searchParamsString ? `?${searchParamsString}` : ''}`,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLiveTailEnabled]);

  const overlayMessage = useAnalyticsChartOverlayMessage({
    chartData: analyticsData.chartData,
    activeVisualization: analyticsChart.activeVisualization,
    errorMessage: aggregateTimeSeriesMultipleV2Request.error
      ? 'Error fetching data.'
      : undefined,
  });

  const renderValue = useMemo(
    () =>
      searches[0].measure === 'duration_ns'
        ? (n: number) => formatDurationNs(n, 1, 2)
        : null,
    [searches],
  );

  return (
    <div className="traces__timeseries">
      <AnalyticsChart
        analyticsChart={analyticsChart}
        chartData={analyticsData.chartData}
        chartFormulas={analyticsData.formulaQueries}
        chartQueries={analyticsData.chartQueries}
        defaultActiveChart="Stacked Bar"
        groupBy={groupBysForTable}
        overlayMessage={overlayMessage}
        renderValue={renderValue}
        rightToolbar={{
          onExportClick: openExportToDashboardModal,
          onSaveClick: enableTraceSaveMetrics ? openSaveMetricModal : undefined,
          onViewFullscreen,
          saveButtonDisabledReasonIfDisabled,
        }}
        setDate={setDate}
        settingOptions={{
          legendType: 'values',
          toolbarChartShowType: 'dropdown',
        }}
      />
    </div>
  );
};

export default TracesTimeseries;
