import classnames from 'classnames';
import {
  Loader,
  OverlayMessage,
  OverlayMessageProps,
  TooltipTrigger,
} from 'components';
import dayjs from 'dayjs';
import {
  FilterType,
  Operator,
  useRequest,
  useSearch,
  useTracesState,
  useUrlState,
} from 'hooks';
import { timeFormats } from 'kfuse-constants';
import VisibilitySensor from 'react-visibility-sensor';
import React, { useEffect, useState } from 'react';
import { ExternalLink } from 'react-feather';
import { traceErrorGroups } from 'requests';
import { Service, TraceErrorGroup } from 'types';
import { formatNumber, NANO_TO_MILLI_SECONDS } from 'utils';
import { Table, TableColumnType } from '../Table';
import ApmErrorsGroupTableSidebar from './ApmErrorsGroupTableSidebar';
import ApmErrorGroupChartSensor from './ApmErrorGroupChartSensor';
import APMErrorGroupTableRow from './APMErrorGroupTableRow';

const columns = ({
  operation,
  measure,
  isServiceFromDatabasesList,
  serviceHash,
  rollUpInSeconds,
  tracesState,
  onOpenInErrorPage,
}: {
  operation: string;
  measure: string;
  isServiceFromDatabasesList: boolean;
  serviceHash: string;
  rollUpInSeconds: number;
  tracesState: ReturnType<typeof useTracesState>;
  onOpenInErrorPage: (row: TraceErrorGroup) => void;
}) => [
  {
    key: 'errorGroupingKey',
    label: 'Group ID',
    renderCell: ({ row }: { row: TraceErrorGroup }) => (
      <VisibilitySensor
        onChange={(isVisible) => {
          if (isVisible) {
            row?.onFetchErrorGroupingKey(row.errorGroupingKey);
          }
        }}
      >
        <div>{row.errorGroupingKey}</div>
      </VisibilitySensor>
    ),
  },
  {
    key: 'errorType',
    label: 'Type',
    renderCell: ({ row }: { row: TraceErrorGroup }) =>
      row?.optimizedRowData?.errorType,
  },
  {
    key: 'message',
    label: 'Error Message and Culprit',
    renderCell: ({ row }: { row: TraceErrorGroup }) => (
      <div>
        <div>{row?.optimizedRowData?.message}</div>
        <div className="text--monospace">{row?.optimizedRowData?.culprit}</div>
      </div>
    ),
  },
  {
    key: 'numOccurrences',
    label: 'Occurrences',
    renderCell: ({ row }: { row: TraceErrorGroup }) =>
      formatNumber(row?.optimizedRowData?.numOccurrences || 0),
    type: TableColumnType.NUMBER,
  },
  {
    key: 'lastOccurrenceNs',
    label: 'Last Occurrences',
    renderCell: ({ row }: { row: TraceErrorGroup }) =>
      row?.optimizedRowData?.lastOccurrenceNs
        ? dayjs(
            row.optimizedRowData.lastOccurrenceNs / NANO_TO_MILLI_SECONDS,
          ).format(timeFormats.dateAtTime)
        : '',
  },
  {
    key: 'chart',
    label: 'Chart',
    renderCell: ({ row }: { row: TraceErrorGroup }) => (
      <ApmErrorGroupChartSensor
        operation={operation}
        measure={measure}
        isServiceFromDatabasesList={isServiceFromDatabasesList}
        serviceHash={serviceHash}
        rollUpInSeconds={rollUpInSeconds}
        tracesState={tracesState}
        row={row}
      />
    ),
  },
  {
    key: 'Actions',
    label: 'Actions',
    renderCell: ({ row }: { row: TraceErrorGroup }) => {
      return (
        <div className="flex--justify-content-center flex">
          <button
            className="link"
            onClick={(e) => {
              onOpenInErrorPage(row);
              e.stopPropagation();
            }}
          >
            <TooltipTrigger tooltip="Open in error page">
              <ExternalLink size={16} />
            </TooltipTrigger>
          </button>
        </div>
      );
    },
  },
];

type Props = {
  applyFilterOnErrorsPage?: boolean;
  className?: string;
  colorsByServiceHash: Record<string, string>;
  customerFilter: { [key: string]: string };
  isServiceFromDatabasesList?: boolean;
  serviceHash?: string;
  serviceByHash: Record<string, Service>;
  serviceName?: string;
  tracesState: ReturnType<typeof useTracesState>;
};

const ApmErrorsGroupTable = ({
  applyFilterOnErrorsPage,
  className,
  colorsByServiceHash,
  customerFilter,
  isServiceFromDatabasesList,
  serviceHash,
  serviceByHash,
  serviceName,
  tracesState,
}: Props) => {
  const [traceErrorGroupId, setTraceErrorGroupId] = useUrlState(
    'traceErrorGroupId',
    null,
  );

  const traceErrorGroupsRequest = useRequest(traceErrorGroups, true, true);

  const [allRows, setAllRows] = useState([]);

  useEffect(() => {
    if (
      traceErrorGroupsRequest.result &&
      traceErrorGroupsRequest.result.length
    ) {
      setAllRows(traceErrorGroupsRequest.result.slice(0, 100));
    } else {
      setAllRows([]);
    }
  }, [traceErrorGroupsRequest.result]);

  const onScrollEnd = () => {
    if (
      traceErrorGroupsRequest.result &&
      allRows.length < traceErrorGroupsRequest.result.length
    ) {
      if (allRows.length < traceErrorGroupsRequest.result.length) {
        setAllRows((prevRows) => [
          ...prevRows,
          ...traceErrorGroupsRequest.result.slice(
            allRows.length,
            allRows.length + 100,
          ),
        ]);
      }
    }
  };

  const search = useSearch();
  const { measure, operation, rollUpInSeconds } = search.state;

  const [error, setError] = useState({
    getTraceErrors: null,
    getGroupErrors: null,
  });

  useEffect(() => {
    setAllRows([]);
    traceErrorGroupsRequest.call({
      isListView: true,
      isServiceFromDatabasesList,
      serviceHash,
      tracesState,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tracesState.state]);

  const close = () => {
    setTraceErrorGroupId(null);
  };

  const onRowClick = ({ row }) => {
    setTraceErrorGroupId(row.errorGroupingKey);
  };

  const onOpenInErrorPage = (row: TraceErrorGroup) => {
    const filters = [
      ...(serviceHash
        ? [
            {
              type: FilterType.selectedFacetValue,
              value: {
                facet: isServiceFromDatabasesList
                  ? 'kf_database_service_hash'
                  : 'service_hash',
                operator: Operator.equal,
                values: {
                  [serviceHash]: 1,
                },
              },
            },
          ]
        : []),
      {
        type: FilterType.selectedFacetValue,
        value: {
          facet: 'error_grouping_key',
          operator: Operator.equal,
          values: {
            [row.errorGroupingKey]: 1,
          },
        },
      },
    ];
    window.open(
      `#/apm/errors/all?apmErrorsFilters=${JSON.stringify(
        filters,
      )}&apmDate=${JSON.stringify(tracesState.dateState[0])}
      &customerFilter=${JSON.stringify(customerFilter)}
      `,
    );
  };

  const overlayMessageProps: OverlayMessageProps = error.getTraceErrors
    ? {
        isActive: true,
        iconName: 'warning',
        message: (
          <>
            {`${error?.getTraceErrors?.message}.` || 'Error.'} Please double
            check your query and try again.
          </>
        ),
      }
    : { isActive: false };

  return (
    <>
      <div className={classnames({ [className]: className })}>
        <Loader isLoading={traceErrorGroupsRequest.isLoading}>
          {!traceErrorGroupsRequest.isLoading &&
          traceErrorGroupsRequest.result &&
          !traceErrorGroupsRequest.result.length ? (
            <div className="placeholder">{`No error groups${
              serviceName ? ` for ${serviceName}` : ''
            }`}</div>
          ) : (
            <OverlayMessage {...overlayMessageProps}>
              <Table
                className="table--padded table--bordered table--bordered-cells"
                columns={columns({
                  operation,
                  measure,
                  isServiceFromDatabasesList,
                  serviceHash,
                  rollUpInSeconds,
                  tracesState,
                  onOpenInErrorPage,
                })}
                onRowClick={onRowClick}
                rows={allRows}
                renderRow={(tableRowProps) => (
                  <APMErrorGroupTableRow
                    isServiceFromDatabasesList={isServiceFromDatabasesList}
                    serviceHash={serviceHash}
                    tracesState={tracesState}
                    tableRowProps={tableRowProps}
                  />
                )}
                onScrollEnd={onScrollEnd}
              />
            </OverlayMessage>
          )}
        </Loader>
      </div>
      {traceErrorGroupId ? (
        <ApmErrorsGroupTableSidebar
          applyFilterOnErrorsPage={applyFilterOnErrorsPage}
          close={close}
          colorsByServiceHash={colorsByServiceHash}
          customerFilter={customerFilter}
          isServiceFromDatabasesList={isServiceFromDatabasesList}
          serviceByHash={serviceByHash}
          errorGroupingKey={traceErrorGroupId}
          serviceHash={serviceHash}
          tracesState={tracesState}
        />
      ) : null}
    </>
  );
};

export default ApmErrorsGroupTable;
