import { FiltersOnEnterArgs, Icon, IconWithLabel, Search } from 'components';
import { useFeatureFlag } from 'configcat-react';
import { CoreLabels, delimiter } from 'kfuse-constants';
import {
  Filter,
  FilterType,
  Operator,
  SelectedFacetValueFilter,
  getExistingFilterWithSameFacet,
  getLegacyLogsStateFromFiltersState,
  getTags,
  useFiltersState,
  useRequest,
  BitmapFilter,
} from 'hooks';
import { getLabelNames, getLabelValues } from 'requests';
import React, { ReactNode, useMemo, useState } from 'react';
import { DateSelection } from 'types';
import {
  getFacetKey,
  getFacetValuesOptions,
  getIsInQuotes,
  getRange,
  groupLabels,
  parsePartialSearchQuery,
} from 'utils';
import LogsSearchBarV2Panel from './LogsSearchBarV2Panel';

const getFacetKeyFromFacetName = ({
  facetName,
  meta,
}: {
  facetName: string;
  meta: Record<string, string>;
}) => {
  const facetNameParts = facetName.split(':');
  const component = facetNameParts.length > 1 ? facetNameParts[0] : '';

  let effectiveFacetName = '';
  if (meta?.facetName && meta?.displayName) {
    effectiveFacetName = meta.facetName;
  } else if (facetNameParts.length > 1) {
    effectiveFacetName = facetNameParts.slice(1).join(':');
  } else {
    effectiveFacetName = facetNameParts[0];
  }

  const metaType =
    meta && meta.facetName === effectiveFacetName ? meta.type : null;
  const facetKey = getFacetKey({
    component,
    name: effectiveFacetName,
    type: metaType || 'string',
    displayName: meta?.displayName,
  });

  return facetKey;
};

const getValues = (value: string) => {
  const facetValues = value
    .split(' OR ')
    .map((v) => v.trim())
    .filter((v) => v);

  return facetValues.reduce(
    (obj, facetValue) => ({ ...obj, [facetValue]: 1 }),
    {},
  );
};

const getSanitizedFacetName = (facetName: string) =>
  facetName?.startsWith('@') ? facetName?.slice(1) : facetName;

const getShouldShowSearchTermsWithoutSourceWarning = (filters: Filter[]) => {
  const searchTerm = filters.find(
    (filter) => filter.type === FilterType.searchTerms,
  );

  if (searchTerm) {
    const sourceFacetKey = getFacetKey({
      component: 'Core',
      name: 'source',
      type: 'string',
    });

    return !filters.find(
      (filter) =>
        filter.type === FilterType.selectedFacetValue &&
        filter.value.facet === sourceFacetKey,
    );
  }

  return false;
};

type Props = {
  customerFilter?: { key: string; value: string };
  date: DateSelection;
  filtersState: ReturnType<typeof useFiltersState>;
  getFacetNamesRequest: ReturnType<typeof useRequest>;
};

const LogsSearchBarV2 = ({
  customerFilter,
  date,
  filtersState,
  getFacetNamesRequest,
}: Props) => {
  const { value: isLogsGrepSearchDisabled } = useFeatureFlag(
    'isLogsGrepSearchDisabled',
    false,
  );
  const validateTyped = (typed: string) => {
    if (isLogsGrepSearchDisabled) {
      if (getIsInQuotes(typed)) {
        return 'Grep search is currently disabled';
      }
    }

    return null;
  };

  const clear = () => {
    filtersState.setState([]);
  };

  const { filterOrExcludeByFingerprint } = getLegacyLogsStateFromFiltersState({
    filtersState,
  });

  const onEnter = ({ index, meta, replace, typed }: FiltersOnEnterArgs) => {
    const shouldReplace = replace && typeof index === 'number';
    const replaceFilter = (filter: Filter) => {
      filtersState.replaceByIndex(filter, index);
    };
    const applyFilter = shouldReplace ? replaceFilter : filtersState.add;

    const isInQuotes = getIsInQuotes(typed);
    if (isInQuotes) {
      applyFilter({ type: FilterType.searchTerms, value: typed });
      return;
    }

    const { facetName, operator, operatorIndex, value } =
      parsePartialSearchQuery(typed, false);

    if (operator === Operator.equal || operator === Operator.notEqual) {
      if (facetName === 'fingerprint') {
        applyFilter({
          type: FilterType.filterOrExcludeByFingerprint,
          value: { facet: value, operator },
        });
        return;
      }

      if (facetName === 'key exists') {
        const facetNameParts = value.split(':');
        const component = facetNameParts.length > 1 ? facetNameParts[0] : '';
        const effectiveFacetName =
          facetNameParts.length > 1
            ? facetNameParts.slice(1).join(':')
            : facetNameParts[0];

        const metaType =
          meta && meta.facetName === effectiveFacetName ? meta.type : null;
        const santizedFacetName = getSanitizedFacetName(effectiveFacetName);

        const facetKey = getFacetKey({
          component,
          name: santizedFacetName,
          type: metaType || 'string',
        });

        applyFilter({
          type: FilterType.keyExists,
          value: { facet: facetKey, operator },
        });
        return;
      }

      const facetKey = getFacetKeyFromFacetName({ facetName, meta });
      const values = getValues(value);

      const {
        existingFilterWithSameFacetIndex,
        existingFilterValues,
        shouldReplaceExistingFilter,
      } = getExistingFilterWithSameFacet({
        facetName: facetKey,
        filters: filtersState.state,
        operator,
        replace,
      });

      const filter: SelectedFacetValueFilter = {
        type: FilterType.selectedFacetValue,
        value: {
          facet: facetKey,
          operator,
          values: { ...existingFilterValues, ...values },
        },
      };

      if (shouldReplaceExistingFilter) {
        filtersState.replaceByIndex(filter, existingFilterWithSameFacetIndex);
      } else {
        applyFilter(filter);
      }

      return;
    }

    if (
      operator === Operator.regex ||
      operator === Operator.notRegex ||
      operator === Operator.facetTermsExist ||
      operator === Operator.notFacetTermsExist ||
      operator === Operator.startsWith ||
      operator === Operator.contains ||
      operator === Operator.endsWith
    ) {
      const facetKey = getFacetKeyFromFacetName({ facetName, meta });
      const values = getValues(value);

      const filter: SelectedFacetValueFilter = {
        type: FilterType.selectedFacetValue,
        value: { facet: facetKey, operator, values },
      };

      applyFilter(filter);
      return;
    }

    if (
      operator === Operator.greaterThan ||
      operator === Operator.lessThan ||
      operator === Operator.greaterThanOrEqualTo ||
      operator === Operator.lessThanOrEqualTo
    ) {
      const {
        facetName: rangeFacetName,
        isDuration,
        ...range
      } = getRange({
        firstOperator: operator,
        firstOperatorIndex: operatorIndex,
        typed,
        useNs: true,
      });

      const facetNameParts = rangeFacetName.split(':');
      const component = facetNameParts.length > 1 ? facetNameParts[0] : '';
      const facetName =
        facetNameParts.length > 1
          ? facetNameParts.slice(1).join(':')
          : facetNameParts[0];
      const santizedFacetName = getSanitizedFacetName(facetName);
      const metaTypeLowercased =
        meta &&
        meta.type &&
        meta.facetName &&
        getSanitizedFacetName(meta.facetName) === santizedFacetName
          ? meta.type.toLowerCase()
          : null;

      const metaType =
        metaTypeLowercased === 'duration' || metaTypeLowercased === 'number'
          ? meta.type
          : null;

      const parsedType = isDuration ? 'Duration' : null;
      const type = metaType || parsedType || 'Number';
      const facetKey = [component, santizedFacetName, type].join(delimiter);

      applyFilter({
        type: FilterType.selectedRange,
        value: { facet: facetKey, ...range },
      });
      return;
    }

    applyFilter({ type: FilterType.searchTerms, value: typed });
  };

  const getLabelNamesRequest = useRequest((args) =>
    getLabelNames(args).then((response) => {
      const { additionalLabels, cloudLabels, kubernetesLabels } = groupLabels(
        response || [],
      );

      return {
        additional: additionalLabels,
        cloud: cloudLabels,
        core: CoreLabels,
        kubernetes: kubernetesLabels,
      };
    }),
  );

  const tags = useMemo(() => {
    const getMeta = (filter: Filter) => {
      if (
        filter.type === FilterType.keyExists ||
        filter.type === FilterType.selectedFacetValue ||
        filter.type === FilterType.selectedRange
      ) {
        const [_, facetName, type, displayName] =
          filter.value.facet.split(delimiter);
        return { displayName, facetName, type };
      }

      return {};
    };

    const renderFacet = (facet: string) => {
      const [component, facetName, _, displayName] = facet.split(delimiter);
      return `${component ? `${component}:` : ''}${displayName || facetName}`;
    };

    return getTags({
      colorsByServiceHash: {},
      filtersState,
      getMeta,
      renderFacet,
      serviceByHash: {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersState.state]);

  const [facetValueOptionsByFacetName, setFacetValueOptionsByFacetName] =
    useState({});

  const fetchFacetValuesByFacetName = (facetName) => {
    return getLabelValues({
      facet: { name: facetName.split(':')[1], type: 'STRING' },
      logsState: {
        customerFilter: customerFilter,
        date,
        filterByFacets: [],
        fingerprintQuery: null,
        filterOrExcludeByFingerprint: {},
        keyExists: {},
        searchTerms: [],
        selectedFacetValues: {},
        selectedFacetRanges: {},
      },
    })
      .then((facetValues: any) => {
        const facetValuesOptions = getFacetValuesOptions(facetValues);
        const sortedFacetValuesOptions = facetValuesOptions.sort((a, b) =>
          a.count > b.count ? -1 : 1,
        );
        return sortedFacetValuesOptions;
      })
      .then((options) => {
        setFacetValueOptions(facetName, options);
      });
  };

  const setFacetValueOptions = (
    facetName: string,
    options: { label: ReactNode; value: any }[],
  ) => {
    setFacetValueOptionsByFacetName((prevFacetValueOptionsByFacetName) => ({
      ...prevFacetValueOptionsByFacetName,
      [facetName]: options,
    }));
  };

  const onPanelOpen = () => {
    if (!getLabelNamesRequest.calledAtLeastOnce) {
      getLabelNamesRequest.call({
        logsState: { date, filterOrExcludeByFingerprint },
      });
    }
  };

  const onToggleKeyExists = (facet) => {
    filtersState.add({
      type: FilterType.keyExists,
      value: {
        facet,
        operator: Operator.equal,
      },
    } as BitmapFilter);
  };

  const shouldShowSearchTermsWithoutSourceWarning =
    getShouldShowSearchTermsWithoutSourceWarning(filtersState.state);

  return (
    <div className="logs__search__top__inner">
      <Search
        clear={clear}
        onEnter={onEnter}
        placeholder="Search logs"
        renderTypedPanel={({
          close,
          editIndex,
          error,
          onValueSelect,
          setMeta,
          setTyped,
          typed,
        }) => (
          <LogsSearchBarV2Panel
            close={close}
            date={date}
            editIndex={editIndex}
            error={error}
            fetchFacetValuesByFacetName={fetchFacetValuesByFacetName}
            facetValueOptionsByFacetName={facetValueOptionsByFacetName}
            getFacetNamesRequest={getFacetNamesRequest}
            getLabelNamesRequest={getLabelNamesRequest}
            onPanelOpen={onPanelOpen}
            onToggleKeyExists={onToggleKeyExists}
            setMeta={setMeta}
            setTyped={setTyped}
            tags={tags}
            typed={typed}
            onValueSelect={onValueSelect}
          />
        )}
        shouldUseReplace
        tags={tags}
        validateTyped={validateTyped}
      />
      {shouldShowSearchTermsWithoutSourceWarning ? (
        <div className="logs__search__warning">
          <IconWithLabel
            icon={<Icon icon="alert-triangle" />}
            label="Consider selecting a source when using search terms"
          />
        </div>
      ) : null}
    </div>
  );
};

export default LogsSearchBarV2;
