import dayjs from 'dayjs';
import {
  ChipWithLabel,
  ServiceWithLabels,
  TableColumnType,
  TooltipTrigger,
} from 'components';
import {
  ServiceTableKpiKeys,
  dateTimeFormat,
  serviceTableKpis,
} from 'kfuse-constants';
import React from 'react';
import { Property } from './types';
import { getBoundedValueForLatency } from 'screens/Services/utils';

const getServiceTabTableColumns = ({
  colorMap,
  kpisBySpanName,
  isLatencyBoundedToMinMax,
  isServiceFromDatabasesList,
  navigateToTracesPageHander,
  property,
  serviceByHash,
}) => {
  const result = [
    {
      key: 'name',
      label:
        property === 'client_service_hash' || property === 'service_hash'
          ? 'Service'
          : property.replace(/\_/g, ' '),
      renderCell: ({ row, value }: { value: string }) => {
        if (property === 'client_service_hash' || property === 'service_hash') {
          const [name, hash] = value.split(':');
          const service = serviceByHash[hash];

          if (service) {
            return (
              <button onClick={navigateToTracesPageHander(hash)}>
                <ServiceWithLabels
                  className="apm__service__table__name"
                  color={colorMap[name]}
                  distinctLabels={service?.distinctLabels || {}}
                  labels={service?.labels || {}}
                  name={name}
                />
              </button>
            );
          }

          return (
            <ChipWithLabel color={colorMap[value]} label={row.serviceName} />
          );
        }

        return (
          <ChipWithLabel
            color={colorMap[value]}
            label={
              <button
                className={'link'}
                onClick={navigateToTracesPageHander(value)}
              >
                {value === undefined ? 'undefined' : value}
              </button>
            }
          />
        );
      },
      value: ({ row }) => {
        if (property === 'client_service_hash' || property === 'service_hash') {
          const service = serviceByHash[row.name];
          if (service) {
            return `${service.name}:${service.hash}`;
          }
        }

        return row.name;
      },
    },
    {
      key: ServiceTableKpiKeys.eventTime,
      label: 'First seen',
      renderCell: ({ row }: any) => {
        const value =
          kpisBySpanName[row.name] &&
          kpisBySpanName[row.name][ServiceTableKpiKeys.eventTime]
            ? kpisBySpanName[row.name] &&
              kpisBySpanName[row.name][ServiceTableKpiKeys.eventTime]
            : undefined;
        if (!value) return '';
        const updatedDate = dayjs(value);
        if (!updatedDate.isValid()) return '';
        return (
          <TooltipTrigger tooltip={updatedDate.format(dateTimeFormat)}>
            {updatedDate.fromNow()}
          </TooltipTrigger>
        );
      },
    },
    ...serviceTableKpis.map((kpi) => ({
      type: TableColumnType.NUMBER,
      key: kpi.key,
      label: kpi.label,
      renderCell: kpi.renderCell,
      value: ({ row }: { row: { name: string } }) => {
        const value =
          kpisBySpanName[row.name] && kpisBySpanName[row.name][kpi.key]
            ? kpisBySpanName[row.name] && kpisBySpanName[row.name][kpi.key]
            : 0;
        return isLatencyBoundedToMinMax
          ? getBoundedValueForLatency({
              kpiKey: kpi.key,
              maxValue:
                kpisBySpanName[row.name] &&
                (kpisBySpanName[row.name][
                  ServiceTableKpiKeys.maxLatency
                ] as number),
              minValue:
                kpisBySpanName[row.name] &&
                (kpisBySpanName[row.name][
                  ServiceTableKpiKeys.minLatency
                ] as number),
              value: value as number,
            })
          : value;
      },
    })),
  ];

  if (property === Property.version) {
    return result.filter(
      (column) => column.key !== ServiceTableKpiKeys.eventTime,
    );
  }

  return result;
};

export default getServiceTabTableColumns;
