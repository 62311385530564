import { DateSelection } from 'types';
import { generateLogPeriodOptions } from 'composite/utils';
import { DAYS, HOURS, MILLISECONDS, MONTHS, YEARS } from 'kfuse-constants';

import {
  ArchiveInfoProps,
  HydrateFormProps,
  HydrationJobProps,
  JobStatus,
} from './types';

const DEFAULT_TIME_RANGE_DURATION = 60 * 60 * 24 * 7; // 1 week
const RETENTION_DURATION_TO_WARNING_THRESHOLD = 60 * 60 * 24 * 7; // 1 week

export const getDefaultHydrateForm = (
  date: DateSelection,
  archive: ArchiveInfoProps,
): HydrateFormProps => {
  const { FirstArchivedRecordUTCTsMs, name } = archive;

  const endTsUtcAdjusted =
    FirstArchivedRecordUTCTsMs + DEFAULT_TIME_RANGE_DURATION * MILLISECONDS;

  return {
    archiveName: name,
    startTsMs: FirstArchivedRecordUTCTsMs,
    endTsMs: endTsUtcAdjusted,
    retentionDurationValue: 7,
    retentionDurationUnit: 'DAYS',
    filter: [{ key: '', value: '' }],
    addTags: [{ key: '', value: '' }],
  };
};

export const retentionUnitOptions = [
  { label: 'Hours', value: 'HOURS' },
  { label: 'Days', value: 'DAYS' },
  { label: 'Months', value: 'MONTHS' },
  { label: 'Years', value: 'YEARS' },
];

export const FILTER_HELP_MESSAGE =
  'A log event will be hydrated only if it matches all the filters.';
export const TAG_HELP_MESSAGE = `Additional tags which will be added to all the hydrated log events. If a log event already has that specific tag, it will be overwritten.'`;

export const colorsByHydrationJobStatus: { [key: string]: string } = {
  [JobStatus.INIT]: 'var(--ui-interaction-primary-shade)',
  [JobStatus.HYDRATING]: 'var(--ui-interaction-primary-shade)',
  [JobStatus.DONE]: 'var(--ui-status-success)',
  [JobStatus.FAILED]: 'var(--ui-status-danger)',
  [JobStatus.CANCELLED]: 'var(--ui-status-danger)',
};

export const hydrateTimeQuickRangeOptions = () => {
  const upToSevenDays = generateLogPeriodOptions('now-7d');

  // replace all the option with +
  const upToSevenDaysNext = upToSevenDays.map(({ label, from, to }) => {
    return {
      label: label.replace('Last', 'Next'),
      from: from.replace('-', '+'),
      to: to.replace('-', '+'),
    };
  });

  const upToOneYear = [
    { label: 'Next Month', from: 'now+1M', to: 'now' },
    { label: 'Next Year', from: 'now+1y', to: 'now' },
  ];

  return [...upToSevenDaysNext, ...upToOneYear];
};

export const getHydrationJobDate = (job: HydrationJobProps): DateSelection => {
  const { ArchiveStartTsMs, ArchiveEndTsMs } = job;
  const startTs = ArchiveStartTsMs / 1000;
  const endTs = ArchiveEndTsMs / 1000;

  return { startTimeUnix: startTs, endTimeUnix: endTs };
};

export const getRetentionDurationWarning = (
  retentionDurationValue: number,
  retentionDurationUnit: string,
): string => {
  if (!retentionDurationValue || !retentionDurationUnit) {
    return '';
  }

  let retentionDurationInSeconds = 0;
  const retentionDurationValueNum = Number(retentionDurationValue);
  if (retentionDurationUnit === 'HOURS') {
    retentionDurationInSeconds = retentionDurationValueNum * HOURS;
  } else if (retentionDurationUnit === 'DAYS') {
    retentionDurationInSeconds = retentionDurationValueNum * DAYS;
  } else if (retentionDurationUnit === 'MONTHS') {
    retentionDurationInSeconds = retentionDurationValueNum * MONTHS;
  } else if (retentionDurationUnit === 'YEARS') {
    retentionDurationInSeconds = retentionDurationValueNum * YEARS;
  }

  if (retentionDurationInSeconds > RETENTION_DURATION_TO_WARNING_THRESHOLD) {
    return 'Long retention times affect performance. Consider specifying shorter retention times.';
  }

  return '';
};

export const formatNumberWithCommas = (num: number) => {
  if (!num) return '';
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
