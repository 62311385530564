import classnames from 'classnames';
import { PopoverTriggerV2, PopoverPosition } from 'components';
import { useFeatureFlag } from 'configcat-react';
import { useAlertsPageStateContext } from 'context';
import { useAuth, useUrlSearchParams } from 'hooks';
import React, { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import { User, UserRole } from 'types/user';

import HeaderPanel from './HeaderPanel';
import HeaderUserPanel from './HeaderUserPanel';
import {
  adminSubmenu,
  advancedServiceMonitoringFilter,
  alertsSubmenu,
  apmSubmenu,
  dashboardSubmenu,
  grafanaSubmenu,
  infrastructureSubmenu,
  kfuseSubmenu,
  logsSubmenu,
  metricsSubmenu,
  helpSubmenu,
  eventsSubmenu,
  rumSubMenu,
} from './utils';
import HeaderCustomNavLink from './HeaderCustomNavLink';
import { Row } from '../../components/layouts/Flex';

const getLinks = ({
  alertsPageState,
  enableLogsSaveMetrics,
  enableTraceSaveMetrics,
  isAdmin,
  urlSearchParams,
  workbooks,
  showASM,
  showRUM,
  showServerless,
  showKfuseProfiler,
}: {
  alertsPageState: ReturnType<typeof useAlertsPageStateContext>;
  enableLogsSaveMetrics: boolean;
  enableTraceSaveMetrics: boolean;
  isAdmin: boolean;
  urlSearchParams: URLSearchParams;
  workbooks: any[];
  showASM?: boolean;
  showRUM?: boolean;
  showServerless: boolean;
  showKfuseProfiler: boolean;
}) =>
  [
    {
      key: 'apm',
      label: 'APM',
      panel: (
        <HeaderPanel
          items={apmSubmenu(enableTraceSaveMetrics, alertsPageState)}
          urlSearchParams={urlSearchParams}
        />
      ),
      route: `/apm/services`,
      subItemsFunc: apmSubmenu(enableTraceSaveMetrics, alertsPageState),
    },
    {
      key: 'metrics',
      label: 'Metrics',
      panel: <HeaderPanel items={metricsSubmenu} />,
      route: '/metrics',
      subItemsFunc: metricsSubmenu,
    },
    {
      key: 'logs',
      label: 'Logs',
      panel: (
        <HeaderPanel
          items={logsSubmenu(workbooks, enableLogsSaveMetrics, alertsPageState)}
        />
      ),
      route: `/logs`,
      subItemsFunc: logsSubmenu(
        workbooks,
        enableLogsSaveMetrics,
        alertsPageState,
      ),
    },
    {
      key: 'Events',
      label: 'Events',
      panel: <HeaderPanel items={eventsSubmenu} />,
      route: '/events/list',
      subItemsFunc: eventsSubmenu,
    },
    {
      key: 'Alerts',
      label: 'Alerts',
      panel: <HeaderPanel items={alertsSubmenu} />,
      route: '/alerts',
      subItemsFunc: alertsSubmenu,
    },
    {
      key: 'grafana-metrics',
      label: 'Grafana',
      panel: <HeaderPanel items={grafanaSubmenu(showKfuseProfiler)} />,
      route: '/grafana-metrics',
      subItemsFunc: grafanaSubmenu(showKfuseProfiler),
    },
    {
      key: 'dashboard',
      label: 'Dashboards',
      route: '/dashboards/lists',
      panel: <HeaderPanel items={dashboardSubmenu} />,
      subItemsFunc: dashboardSubmenu,
    },
    {
      key: 'infrastructure',
      label: 'Infrastructure',
      panel: <HeaderPanel items={infrastructureSubmenu(showServerless)} />,
      route: '/kubernetes',
      subItemsFunc: infrastructureSubmenu(showServerless),
    },
    showASM
      ? {
          key: 'advanced-service-monitoring',
          label: 'ASM',
          route: `/advanced-service-monitoring?${advancedServiceMonitoringFilter}`,
        }
      : null,
    {
      key: 'admin',
      label: 'Admin',
      panel: <HeaderPanel items={() => adminSubmenu(isAdmin)} />,
      route: isAdmin ? '/admin/users' : '/admin/folders',
      subItemsFunc: adminSubmenu,
    },
    showRUM
      ? {
          key: 'rum',
          label: 'RUM',
          panel: <HeaderPanel items={rumSubMenu} />,
          route: '/rum/list',
          subItemsFunc: rumSubMenu,
        }
      : null,
  ].filter(Boolean);

const getLinksRight = (urlSearchParams: URLSearchParams) => [
  {
    key: 'control-plane',
    label: 'Control Plane',
    panel: <HeaderPanel items={kfuseSubmenu} className="right-0" />,
    route: '/kfuse/overview',
    subItemsFunc: kfuseSubmenu,
  },
  {
    key: 'help',
    label: 'Help',
    panel: <HeaderPanel items={helpSubmenu} className="right-0" />,
    route: '',
    subItemsFunc: helpSubmenu,
  },
];

type Props = {
  auth: ReturnType<typeof useAuth>;
  workbooks: any[];
};

const Header = ({ auth, workbooks }: Props): ReactElement => {
  const { value: showServerless } = useFeatureFlag('serverless', false);
  const { value: showASM } = useFeatureFlag('showasm', false);
  const { value: showRUM } = useFeatureFlag('showRUM', false);
  const { value: enableSaveMetrics } = useFeatureFlag(
    'enableSaveMetrics',
    false,
  );
  const { value: enableTraceSaveMetrics } = useFeatureFlag(
    'enableTraceSaveMetrics',
    false,
  );
  const { value: showKfuseProfiler } = useFeatureFlag(
    'showKfuseProfiler',
    false,
  );

  const urlSearchParams = useUrlSearchParams();
  const user = auth?.user as User;

  const alertsPageState = useAlertsPageStateContext();
  return (
    <Row className="header">
      <NavLink className="header__home" to="/">
        K
      </NavLink>
      <Row className="header__nav">
        {getLinks({
          alertsPageState,
          enableLogsSaveMetrics: enableSaveMetrics,
          enableTraceSaveMetrics: enableTraceSaveMetrics,
          isAdmin: user?.role === UserRole.ADMIN,
          urlSearchParams,
          workbooks,
          showServerless,
          showASM,
          showRUM,
          showKfuseProfiler,
        }).map((link) => (
          <div key={link.key} className={classnames('header__nav__item')}>
            <HeaderCustomNavLink link={link} />
            {link.panel && <div>{link.panel}</div>}
          </div>
        ))}
      </Row>
      <Row className="header__nav__right">
        {getLinksRight(urlSearchParams).map((link) => (
          <div key={link.key} className={classnames('header__nav__item')}>
            <HeaderCustomNavLink link={link} />
            {link.panel && <div>{link.panel}</div>}
          </div>
        ))}
      </Row>
      <PopoverTriggerV2
        className="header__user"
        popover={(props) => <HeaderUserPanel {...props} auth={auth} />}
        position={PopoverPosition.BOTTOM_RIGHT}
      >
        <img
          className="header__user__avatar"
          referrerPolicy="no-referrer"
          src={user?.imageUrl}
        />
      </PopoverTriggerV2>
    </Row>
  );
};

export default Header;
