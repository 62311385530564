import {
  AnalyticsChartQueryProps,
  AnalyticsChartFullscreenProps,
  TimeseriesExplorer,
  useAnalyticsChart,
  useModalsContext,
} from 'components';
import { useRequest, useSearches } from 'hooks';
import React, { useMemo } from 'react';
import { DashboardPanelType, QueryDataProps } from 'types';
import useDebouncedEffect from 'use-debounced-effect';

import { eventsDataTransformer } from 'utils/DataTransformer';
import useEventsState from './useEventsState';
import aggregateEventTimeSeries from '../requests/aggregateEventTimeSeries';
import eventsDataFrameToQuery from 'utils/DataTransformer/events/eventsDataFrameToQuery';

type Props = {
  searches: ReturnType<typeof useSearches>;
  eventsState: ReturnType<typeof useEventsState>;
  tab: string;
};

const useEventsTimeseries = ({ searches, eventsState, tab }: Props) => {
  const modals = useModalsContext();

  const { date } = eventsState;

  const analyticsChart = useAnalyticsChart({
    date: date,
    forcedActiveVisualization: tab as DashboardPanelType,
    hideVisualizeToolBar: true,
    supportedVisualizations: [
      DashboardPanelType.TIMESERIES,
      DashboardPanelType.TOP_LIST,
      DashboardPanelType.TABLE,
      DashboardPanelType.PIECHART,
    ],
  });
  const { activeVisualization } = analyticsChart;

  const aggregateTimeSeriesMultipleV2Request = useRequest((args) =>
    aggregateEventTimeSeries(args).then(eventsDataFrameToQuery),
  );
  const queries = searches.map((s) => s.state);

  const rangeKey = activeVisualization === 'timeseries' ? 'range' : 'instant';

  const onViewFullscreen = ({
    activeChart,
    chartQueries,
    prevChartData,
    unit,
  }: AnalyticsChartFullscreenProps) => {
    modals.push(
      <TimeseriesExplorer
        activeQueries={[...chartQueries]}
        activeChartType={activeChart}
        chartData={prevChartData}
        date={date}
        queryType="events"
        onClose={modals.pop}
        unit={unit}
      />,
    );
  };

  const analyticsData = useMemo(() => {
    if (!aggregateTimeSeriesMultipleV2Request.result) {
      return { chartData: {}, chartQueries: [], formulaQueries: [] };
    }

    const datasets = aggregateTimeSeriesMultipleV2Request.result;
    const isLoading = aggregateTimeSeriesMultipleV2Request.isLoading;
    const chartData: QueryDataProps = {};
    const chartQueries: AnalyticsChartQueryProps[] = [];

    searches.forEach((search, idx) => {
      const queryId = `query_${search.queryKey}`;
      if (!datasets || !datasets[queryId]) {
        chartData[queryId] = { [rangeKey]: null, isLoading, meta: null };
        return;
      }

      const dataset = datasets[queryId];
      if (isLoading) {
        chartData[queryId] = { [rangeKey]: null, isLoading, meta: null };
        return;
      }

      if (activeVisualization !== dataset.meta?.type) {
        return;
      }

      const isTimeseries = dataset.meta?.type === 'timeseries';
      chartData[queryId] = {
        [rangeKey]: isTimeseries ? dataset.data : dataset,
        isLoading,
        meta: dataset.meta,
      };
    });

    searches.forEach((search, idx) => {
      const query = search.state;
      chartQueries.push({
        direction: query.limitTo,
        filter: search.state.searchBarState.filterState,
        index: idx,
        isActive: query.isActive,
        type: 'query',
        query,
        queryKey: query.queryKey,
        steps: query.rollUpInSeconds,
        vectorAggregate:
          query.operation === 'distinctcount' ? 'count' : query.operation,
        unit: query.measure === 'duration_ns' ? 'ns' : undefined,
      });
    });

    return { chartData, chartQueries };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    aggregateTimeSeriesMultipleV2Request.result,
    aggregateTimeSeriesMultipleV2Request.isLoading,
    searches,
    activeVisualization,
  ]);

  const groupBysForTable = useMemo(() => {
    const res: string[] = [];
    queries
      .filter((item) => item.isActive)
      .map((q) => {
        const gr: string[] = [];
        q.groupBys.map((g) => {
          g !== '*' && gr.push(g);
        });
        const isLabel = res.find((g) => g === 'label');
        if (!isLabel && gr.length === 0) {
          gr.push('label');
        }
        res.push(...gr);
      });

    return res;
  }, [queries]);

  const loadEventTimeseries = () => {
    const instant = activeVisualization !== 'timeseries';
    const baseTransformers = eventsDataTransformer(instant);

    const preparedQueries = searches
      .map((s) => (s.searchBarState ? s.state : undefined))
      .filter(Boolean);
    aggregateTimeSeriesMultipleV2Request.call({
      date,
      dataFormat: activeVisualization,
      formulas: [],
      instant,
      queries: preparedQueries,
      transformer: baseTransformers,
    });
  };

  useDebouncedEffect(
    () => {
      loadEventTimeseries();
    },
    100,
    [activeVisualization, date, searches],
  );

  return {
    analyticsChart,
    analyticsData,
    groupBysForTable,
    onViewFullscreen,
  };
};

export default useEventsTimeseries;
