import { Loader, useLeftSidebarState } from 'components';
import {
  useDateState,
  useRequest,
  useSelectedFacetValuesByNameState,
} from 'hooks';
import React, { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { traceLabelValuesV2 } from 'requests';
import { ServicesTab } from 'screens/Services/utils';
import {
  ChartGridKeysState,
  SelectedFacetValuesByName,
  Service as ServiceType,
} from 'types';
import Service from './Service';
import useDebouncedEffect from 'use-debounced-effect';

type Props = {
  apmDateState: ReturnType<typeof useDateState>;
  chartGridKeysState: ChartGridKeysState;
  customerFilter: { key: string; value: string };
  getServicesRequest: ReturnType<typeof useRequest>;
  isAsmChecked: boolean;
  kfMetricsAgentName: string;
  leftSidebarState: ReturnType<typeof useLeftSidebarState>;
  selectedFacetValuesByNameState: ReturnType<
    typeof useSelectedFacetValuesByNameState
  >;
  service: ServiceType;
  serviceByHash: Record<string, ServiceType>;
  serviceHash: string;
  telemetrySdkName: string;
};

const ServiceContainer = ({
  apmDateState,
  chartGridKeysState,
  customerFilter,
  getServicesRequest,
  isAsmChecked,
  kfMetricsAgentName,
  leftSidebarState,
  selectedFacetValuesByNameState,
  service,
  serviceByHash,
  serviceHash,
  telemetrySdkName,
}: Props) => {
  const [date] = apmDateState;
  const [params] = useSearchParams();
  const isServiceFromDatabasesList = useMemo(() => {
    const isDatabase = params?.get('activeTab') === ServicesTab.db;
    return isDatabase;
  }, [params]);

  useDebouncedEffect(
    () => {
      const selectedFacetValuesByName: SelectedFacetValuesByName = {
        kf_source: selectedFacetValuesByNameState.state.kf_source,
      };

      getServicesRequest.call({
        customerFilter,
        date,
        selectedFacetValuesByName,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    {
      ignoreInitialCall: false,
      timeout: 50,
    },
    [customerFilter],
  );

  return (
    <Loader isLoading={getServicesRequest.isLoading}>
      {service?.hash === serviceHash ? (
        <Service
          apmDateState={apmDateState}
          getServicesRequest={getServicesRequest}
          isAsmChecked={isAsmChecked}
          isServiceFromDatabasesList={isServiceFromDatabasesList}
          kfMetricsAgentName={kfMetricsAgentName}
          leftSidebarState={leftSidebarState}
          selectedFacetValuesByNameState={selectedFacetValuesByNameState}
          service={service.name}
          serviceDistinctLabels={service.distinctLabels || {}}
          serviceLabels={service.labels}
          serviceByHash={serviceByHash}
          serviceHash={service.hash}
          chartGridKeysState={chartGridKeysState}
          telemetrySdkName={telemetrySdkName}
        />
      ) : null}
    </Loader>
  );
};

export default ServiceContainer;
