import { AutocompleteOption } from 'components';
import { debounce } from 'lodash';
import delimiter from 'kfuse-constants/delimiter';
import { useEffect, useMemo, useRef, useState } from 'react';
import { getFiltersStateFromArrayStateItem, useRequest } from 'hooks';
import { getLogsFavouriteFacetsByCursor, getLabelNames } from 'requests';
import { DateSelection, LogsMetricQueryProps, DashboardPanelType } from 'types';
import {
  checkLogsMetricType,
  getFacetNamesOptionsForAlert,
  getFilteredLabelOptions,
  getLabelWithDelimiterAndType,
  groupLabels,
  defaultLogsQuery,
  removeLabelFromFacetNames,
  removeDuplicateByKey,
  getIsLogRangeFacet,
} from 'utils';
import { v4 as uuidv4 } from 'uuid';

import useLogsMetricsQueryData from './useLogsMetricsQueryData';
import useLogsMetricsQueryFormula from './useLogsMetricsQueryFormula';
import { nextChar } from '../useSearch/utils';
import { useLatest } from 'react-use';
import { getUsedQueryKeysInFormulas } from 'utils/FormulaValidator/validateLogqlFormulaExpression';

const predefinedQuantiles = {
  P50: 0.5,
  P75: 0.75,
  P90: 0.9,
  P95: 0.95,
  P99: 0.99,
};

const predefinedQuantilesKeys = Object.keys(predefinedQuantiles);

const nextQueryKey = (
  lastQuery: LogsMetricQueryProps,
  deActivateOtherQueries?: boolean,
) => {
  if (!lastQuery) return defaultLogsQuery;
  const copy = JSON.parse(JSON.stringify(lastQuery));
  return {
    ...copy,
    queryKey: nextChar(lastQuery.queryKey),
    isActive: deActivateOtherQueries ? deActivateOtherQueries : copy.isActive,
  };
};

type FacetNamesOptionsBitmapProps = {
  options: AutocompleteOption[];
  cursor: string;
  isLoading?: boolean;
  inputValue?: string;
};

const useLogsMetricsQueryState = ({
  chartWidth,
  customerFilter,
  date,
  dataFormat = DashboardPanelType.TIMESERIES,
  isLogsPage,
  initialState,
  isRange = true,
  labels,
  onChangeLoad = false,
  queriesState,
  urlCleanUp = false,
}: {
  chartWidth?: number;
  customerFilter?: { key: string; value: string };
  date: DateSelection;
  dataFormat?: DashboardPanelType;
  isLogsPage?: boolean;
  initialState?: LogsMetricQueryProps[];
  isRange?: boolean;
  labels?: { [key: string]: AutocompleteOption[] };
  onChangeLoad?: boolean;
  queriesState: ReturnType<typeof useState<LogsMetricQueryProps[]>>;
  urlCleanUp?: boolean;
}) => {
  const [queries, setQueries] = queriesState;

  const [syncSelectedQuery, setSyncSelectedQuery] = useState({
    queryIndex: null,
    uuidForSearchBarStateSync: '',
    lastRefreshedAt: null,
  });
  const [searchBarRect, setSearchBarRect] = useState<DOMRect>(null);
  const facetNamesRequest = useRequest(getLogsFavouriteFacetsByCursor);
  const labelNamesRequest = useRequest(getLabelNames);
  const [facetNamesOptionsBitmap, setFacetNamesOptionsBitmap] = useState<{
    [key: string]: {
      facet: FacetNamesOptionsBitmapProps;
      aggregate?: FacetNamesOptionsBitmapProps;
    };
  }>({});

  const queryOptionsUpdatedAt = useRef<{
    [key: string]: DateSelection;
  }>({});

  const logsMetricsQueryData = useLogsMetricsQueryData({
    chartWidth,
    customerFilter,
    date,
    dataFormat,
    isRange,
  });
  const {
    setLogsChartData,
    loadLogqlQuery,
    loadMultipleLogsChartData,
    loadInstantMultipleLogsChartData,
  } = logsMetricsQueryData;

  const queriesWithFiltersState = useMemo(
    () => {
      return queries.map((query, i) => ({
        ...query,
        filtersState: getFiltersStateFromArrayStateItem({
          key: 'filters',
          index: i,
          setState: setQueries,
          state: queries,
        }),
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queries],
  );

  const logsMetricsQueryFormula = useLogsMetricsQueryFormula({
    chartWidth,
    isRange,
    logsMetricsQueryData,
    queries: queriesWithFiltersState,
    urlCleanUp,
  });

  const latestFormulas = useLatest(logsMetricsQueryFormula.formulas);

  const handleLoadQueryWithState = (
    queries: LogsMetricQueryProps[],
    queryIndex: number,
  ) => {
    const queriesWithFiltersState = queries.map((query, i) => ({
      ...query,
      filtersState: getFiltersStateFromArrayStateItem({
        key: 'filters',
        index: i,
        setState: setQueries,
        state: queries,
      }),
    }));
    const formulaExpressions = latestFormulas.current
      .filter((formula) => formula.isActive)
      .map((formula) => formula.expression);
    const usedKeysInFormulas = getUsedQueryKeysInFormulas(formulaExpressions);

    if (usedKeysInFormulas.includes(queries[queryIndex].queryKey)) {
      logsMetricsQueryFormula.loadLogsAffectedFormula(
        queries[queryIndex].queryKey,
        queriesWithFiltersState,
      );
    } else {
      loadLogqlQuery(queriesWithFiltersState[queryIndex]);
    }
  };

  const handleLoadAllQueriesWithState = ({
    queries,
    isInstant,
  }: {
    queries: LogsMetricQueryProps[];
    isInstant?: boolean;
  }) => {
    const queriesWithFiltersState = queries.map((query, i) => ({
      ...query,
      filtersState: getFiltersStateFromArrayStateItem({
        key: 'filters',
        index: i,
        setState: setQueries,
        state: queries,
      }),
    }));
    if (isInstant) {
      loadInstantMultipleLogsChartData({
        formulas: [],
        queries: queriesWithFiltersState,
        chartWidth,
      });
    } else {
      loadMultipleLogsChartData({
        formulas: [],
        queries: queriesWithFiltersState,
        chartWidth,
      });
    }
  };

  const addQuery = (deActivateOtherQueries: boolean) => {
    setQueries((prevState: LogsMetricQueryProps[]) => {
      const newQueries = [
        ...prevState.map((query) => ({
          ...query,
          isActive: deActivateOtherQueries ? false : query.isActive,
        })),
      ];

      const lastQuery = newQueries[newQueries.length - 1];
      const newQuery = nextQueryKey(lastQuery, deActivateOtherQueries);

      setFacetNamesOptionsBitmap((prevState) => ({
        ...prevState,
        [newQuery.queryKey]: { ...prevState[lastQuery.queryKey] },
      }));

      if (newQuery.isActive) {
        handleLoadQueryWithState([...newQueries, newQuery], newQueries.length);
      }
      return [...newQueries, newQuery];
    });
  };

  const deactivateAllQueries = () => {
    setQueries((prevState: LogsMetricQueryProps[]) =>
      prevState.map((query) => ({ ...query, isActive: false })),
    );
  };

  const forceQueries = (nextQuery: Partial<LogsMetricQueryProps>) => {
    setSyncSelectedQuery((prevState) => ({
      ...prevState,
      queryIndex: 0,
      uuidForSearchBarStateSync: uuidv4(),
      lastRefreshedAt: new Date().valueOf(),
    }));
    setQueries([
      {
        ...defaultLogsQuery,
        ...nextQuery,
      },
    ]);
  };

  const activateOnlySingleQiery = (queryIndex: number) => {
    setQueries((prevState: LogsMetricQueryProps[]) =>
      prevState.map((query, idx) => ({
        ...query,
        isActive: idx === queryIndex,
      })),
    );
  };

  const removeQuery = (index: number) => {
    setQueries((prevState: LogsMetricQueryProps[]) =>
      prevState.filter((_, i) => i !== index),
    );
    setLogsChartData((prevState: any) => {
      const newState = { ...prevState };
      const queryKey = queries[index].queryKey;
      const queryId = `query_${queryKey}`;
      delete newState[queryId];
      return newState;
    });
    setFacetNamesOptionsBitmap((prevState) => {
      const newState = { ...prevState };
      const queryKey = queries[index].queryKey;
      delete newState[queryKey];
      return newState;
    });
    setSyncSelectedQuery({
      queryIndex: null,
      uuidForSearchBarStateSync: uuidv4(),
      lastRefreshedAt: new Date().valueOf(),
    });
  };

  const updateQuery = (
    queryIndex: number,
    propertyKey: string,
    value: any,
    extraParams: {
      activateOnlySingleQiery?: boolean;
      defaultRangeAggregateGroupingOptions?: AutocompleteOption[];
    } = {
      activateOnlySingleQiery: false,
      defaultRangeAggregateGroupingOptions: null,
    },
  ) => {
    setQueries((prevState: LogsMetricQueryProps[]) => {
      const newState = [...prevState];
      const newQuery = { ...newState[queryIndex] };
      newQuery[propertyKey] = value;

      if (
        propertyKey === 'rangeAggregateGrouping' &&
        Boolean(extraParams?.defaultRangeAggregateGroupingOptions)
      ) {
        newQuery.defaultRangeAggregateGroupingOptions =
          extraParams.defaultRangeAggregateGroupingOptions;
      }

      if (propertyKey === 'metric') {
        const parsedMetric = checkLogsMetricType(value);
        const [name, type] = value.split(delimiter);
        if (type === 'DURATION') {
          newQuery.normalizeFunction = 'duration';
        } else if (type === 'SIZE') {
          newQuery.normalizeFunction = 'bytes';
        } else {
          newQuery.normalizeFunction = 'number';
        }

        if (parsedMetric === 'logs') {
          newQuery.rangeAggregate = 'count_over_time';
          newQuery.vectorAggregate = 'sum';
        }

        const isNumericFacet = getIsLogRangeFacet(type);
        if (
          parsedMetric === 'fp' ||
          parsedMetric === 'label' ||
          !isNumericFacet
        ) {
          newQuery.rangeAggregate = 'count_over_time';
          newQuery.vectorAggregate = 'avg';
        }

        if (parsedMetric === 'facet' && isNumericFacet) {
          newQuery.rangeAggregate = 'avg_over_time';
          newQuery.vectorAggregate = 'avg';
        }
      }

      if (propertyKey === 'vectorAggregate') {
        if (predefinedQuantilesKeys.includes(value)) {
          newQuery.rangeAggregate = 'quantile_over_time';
          newQuery.rangeAggregateParam = predefinedQuantiles[value];
        } else {
          newQuery.rangeAggregate = `${value}_over_time`;
        }
      }

      newState[queryIndex] = newQuery;
      if (propertyKey === 'isActive' && extraParams.activateOnlySingleQiery) {
        newState.forEach((query, idx) => {
          if (idx !== queryIndex) {
            newState[idx].isActive = false;
          }
        });
      }
      if (isLogsPage) {
        debounce(() => {
          if (newState[queryIndex].isActive) {
            handleLoadQueryWithState(newState, queryIndex);
          }
        }, 50)();
      }
      if (
        onChangeLoad &&
        propertyKey !== 'showInput' &&
        propertyKey !== 'isActive' &&
        propertyKey !== 'logql'
      ) {
        handleLoadAllQueriesWithState({
          queries: [newQuery],
          isInstant: !isRange,
        });
        logsMetricsQueryFormula.loadLogsAffectedFormula(newQuery.queryKey);
      }
      return newState;
    });
  };

  const labelOptions = useMemo(() => {
    if (labelNamesRequest.result) {
      const groupedLabels = groupLabels(labelNamesRequest.result);
      return getLabelWithDelimiterAndType(groupedLabels);
    }
    if (!labels) return [];
    return getLabelWithDelimiterAndType(labels);
  }, [labels, labelNamesRequest.result]);

  const handleScrollToBottomFacetNames = (
    queryIndex: number,
    type: 'facet' | 'aggregate',
  ) => {
    const { queryKey } = queries[queryIndex];
    if (facetNamesRequest.isLoading) return;
    const { cursor, inputValue } =
      facetNamesOptionsBitmap[queryKey][type] || {};
    if (!cursor) return;
    const isAtTheRate = inputValue?.startsWith('@');
    const prefix = isAtTheRate ? inputValue?.slice(1) : inputValue;
    facetNamesRequest
      .call({ cursor, limit: 1000, contains: prefix })
      .then((resData) => {
        setFacetNamesOptionsBitmap((prevState) => {
          const prevFacet = prevState[queryKey][type]?.options || [];
          const newFacets = getFacetNamesOptionsForAlert({
            facets: resData.facetNames,
            labelOptions: [],
            excludeLF: true,
          });
          const options = [...prevFacet, ...newFacets];
          return {
            ...prevState,
            [queryKey]: {
              ...prevState[queryKey],
              [type]: {
                ...prevState[queryKey][type],
                options,
                cursor: resData.cursor,
                isLoading: false,
              },
            },
          };
        });
      })
      .catch(() =>
        handleLoadingFacetNames({ queryIndex, isLoading: false, type }),
      );
  };

  const handleLoadingFacetNames = ({
    queryIndex,
    isLoading,
    labels = [],
    inputValue = '',
    type,
  }: {
    queryIndex: number;
    isLoading: boolean;
    labels?: AutocompleteOption[];
    inputValue?: string;
    type: 'facet' | 'aggregate';
  }) => {
    const { queryKey } = queries[queryIndex];
    setFacetNamesOptionsBitmap((prevState) => ({
      ...prevState,
      [queryKey]: {
        ...prevState[queryKey],
        [type]: {
          ...prevState[queryKey][type],
          isLoading,
          labels,
          inputValue,
        },
      },
    }));
  };

  const loadFacetNameOnInputChange = (
    queryIndex: number,
    type: 'facet' | 'aggregate',
    search: string,
  ) => {
    if (!search) return;
    const { queryKey } = queries[queryIndex];
    const filteredLabelOptions = getFilteredLabelOptions(labelOptions, search);

    const { inputValue } = facetNamesOptionsBitmap[queryKey][type] || {};
    // if search is empty and inputValue is empty, do nothing
    if (!search && !inputValue) return;

    // if search is empty and inputValue is not empty, reset the facet names options
    if (!search && inputValue) {
      updateBitmapOptions({
        cursor: '',
        options: filteredLabelOptions,
        queryIndex,
        type,
      });
      return;
    }

    const isAtTheRate = search.startsWith('@');
    const prefix = isAtTheRate ? search.slice(1) : search;
    if (!prefix.includes('"')) {
      handleLoadingFacetNames({
        queryIndex,
        isLoading: true,
        labels: filteredLabelOptions,
        inputValue: search,
        type,
      });
      facetNamesRequest
        .call({ limit: 1000, contains: prefix })
        .then((resData) => {
          setFacetNamesOptionsBitmap((prevState) => {
            const newFacets = getFacetNamesOptionsForAlert({
              facets: resData.facetNames,
              labelOptions,
              excludeLF: true,
            });
            const options = [...filteredLabelOptions, ...newFacets];
            const uniqueOptions = removeDuplicateByKey(options, 'value');

            return {
              ...prevState,
              [queryKey]: {
                ...prevState[queryKey],
                [type]: {
                  options: uniqueOptions,
                  cursor: resData.cursor,
                  isLoading: false,
                  inputValue: search,
                },
              },
            };
          });
        })
        .catch(() => {
          updateBitmapOptions({
            cursor: '',
            options: filteredLabelOptions,
            queryIndex,
            type,
          });
        });
    }
  };

  const loadInitalFacetNames = (
    queryIndex: number,
    type: 'facet' | 'aggregate',
  ) => {
    if (!date) {
      return;
    }
    const { queryKey } = queries[queryIndex] || {};
    if (
      queryOptionsUpdatedAt.current[type] === date &&
      facetNamesOptionsBitmap[queryKey]?.[type]?.options?.length
    )
      return;

    // TODO: Check with Khalid if this is the right thing to do
    // if (facetNamesOptionsBitmap[queryKey]?.[type]?.options.length) return;

    facetNamesRequest.call({ limit: 1000, contains: '' }).then((resData) => {
      const options = getFacetNamesOptionsForAlert({
        facets: resData.facetNames,
        labelOptions,
      });
      queryOptionsUpdatedAt.current[type] = date;
      updateBitmapOptions({
        cursor: resData.cursor,
        options,
        queryIndex,
        type,
      });
    });
  };

  const updateBitmapOptions = ({
    cursor,
    options,
    queryIndex,
    type,
  }: {
    cursor: string;
    options: AutocompleteOption[];
    queryIndex: number;
    type: 'facet' | 'aggregate';
  }) => {
    const { queryKey, metric } = queries[queryIndex];
    setFacetNamesOptionsBitmap((prevState) => {
      const isSelectedFacetExist = options.find(
        (option) => option.value === metric,
      );
      if (!isSelectedFacetExist) {
        const label = metric.includes(delimiter)
          ? metric.split(delimiter)[0]
          : metric;
        options = [...options, { label, value: metric }];
      }
      return {
        ...prevState,
        [queryKey]: {
          ...prevState[queryKey],
          [type]: {
            ...prevState[queryKey]?.[type],
            options,
            cursor,
            isLoading: false,
          },
        },
      };
    });
  };

  useEffect(() => {
    if (!queries) return;
    setFacetNamesOptionsBitmap((prevState) => {
      const newState = { ...prevState };
      queries.forEach((query) => {
        const { queryKey, metric } = query;
        const prevFacet = prevState[queryKey]?.facet?.options || [];
        const filteredFacet = removeLabelFromFacetNames(prevFacet);
        const filteredLabelOptions = getFilteredLabelOptions(labelOptions, '');
        let newFacets = [...filteredLabelOptions, ...filteredFacet];

        const isSelectedFacetExist = newFacets.find(
          (option) => option.value === metric,
        );
        if (!isSelectedFacetExist) {
          const label = metric.includes(delimiter)
            ? metric.split(delimiter)[0]
            : metric;
          newFacets = [...newFacets, { label, value: metric }];
        }

        newState[queryKey] = {
          facet: {
            options: newFacets,
            cursor: prevState[queryKey]?.facet?.cursor,
            isLoading: false,
          },
          aggregate: {
            options: newFacets,
            cursor: prevState[queryKey]?.aggregate?.cursor,
            isLoading: false,
          },
        };
      });

      return newState;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labelOptions]);

  useEffect(() => {
    if (isLogsPage || labels) return;
    labelNamesRequest.call({ date, logsState: { date } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labels]);

  return {
    activateOnlySingleQiery,
    addQuery,
    chartWidth,
    deactivateAllQueries,
    facetNamesOptionsBitmap,
    facetNamesRequest,
    forceQueries,
    handleLoadQueryWithState,
    handleScrollToBottomFacetNames,
    labelOptions,
    loadFacetNameOnInputChange,
    loadInitalFacetNames,
    queries: queriesWithFiltersState,
    removeQuery,
    updateQuery,
    searchBarRect,
    setFacetNamesOptionsBitmap,
    setQueries,
    setSearchBarRect,
    syncSelectedQuery,
    setSyncSelectedQuery,
    ...logsMetricsQueryData,
    ...logsMetricsQueryFormula,
  };
};

export default useLogsMetricsQueryState;
