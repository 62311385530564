import classnames from 'classnames';
import {
  ApmErrorsGroupTable,
  DateControls,
  LeftSidebar,
  Loader,
  OverlayMessage,
  OverlayMessageProps,
  ShowSidebarTooltipButton,
  TraceErrorChartGrid,
  useLeftSidebarState,
} from 'components';
import { Datepicker } from 'composite';
import { useRequest } from 'hooks';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  errorLabelNames,
  errorLabelValues,
  getServiceGroupLabelValues,
  getServiceGroupLabels,
  getServices,
  traceErrors,
} from 'requests';
import {
  ApmErrorsTab,
  DateSelection,
  SelectedFacetValuesByName,
  TraceError,
  TracesTab,
} from 'types';
import { getColorsByServiceHash } from 'utils';
import ApmErrorsTable from './ApmErrorsTable';
import ApmErrorsVisualize from './ApmErrorsVisualize';
import TracesSearch from 'screens/Traces/TracesSearch';
import TracesSidebar from 'screens/Traces/TracesSidebar';
import { useApmErrorsPageStateContext } from 'context/PageStateProvider';
import { IoIosWarning } from 'react-icons/io';
import { useFeatureFlag } from 'configcat-react';
import useDebouncedEffect from 'use-debounced-effect';
import CustomerFilter from 'screens/Services/CustomerFilter';

const ApmErrors = () => {
  const pageNumRef = useRef<number>(null);
  const { tab } = useParams();
  const [errorsResult, setErrorsResult] = useState<TraceError[]>([]);
  const leftSidebarState = useLeftSidebarState('apmErrors');
  const getServicesRequest = useRequest(getServices, true, true);
  const traceErrorsRequest = useRequest(traceErrors, true, true);
  const traceLabelNamesRequest = useRequest(errorLabelNames, true, true);
  const getServiceGroupLabelRequest = useRequest(
    getServiceGroupLabels,
    true,
    true,
  );
  const getServiceGroupValuesRequest = useRequest(
    getServiceGroupLabelValues,
    true,
    true,
  );

  const {
    value: isCustomerFilteringEnabled,
    loading: isCustomerFilteringConfigLoading,
  } = useFeatureFlag('isCustomerFilteringEnabled', false);

  const {
    dependenciesForWriteStateToUrl,
    searches,
    tracesState,
    writeStateToUrl,
  } = useApmErrorsPageStateContext();

  const search = searches[0];
  const { customerFilter, dateState, setCustomerFilter } = tracesState;
  const [date, setDate] = dateState;

  const [error, setError] = useState({
    getTraces: null,
    getLabelNames: null,
  });

  useEffect(() => {
    writeStateToUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dependenciesForWriteStateToUrl]);

  const isCustomerFilterSelected = Boolean(customerFilter?.value);

  const showErrorsLandingPage =
    !isCustomerFilteringEnabled || isCustomerFilterSelected;

  useDebouncedEffect(
    () => {
      if (showErrorsLandingPage) {
        const selectedFacetValuesByName = {
          span_type: { db: 0 },
        };
        getServicesRequest.call({
          customerFilter,
          date,
          selectedFacetValuesByName:
            selectedFacetValuesByName as SelectedFacetValuesByName,
        });
        traceLabelNamesRequest
          .call({ date })
          .then(() => {
            setError((prevError) => ({ ...prevError, getLabelNames: null }));
          })
          .catch((err) => {
            setError((prevError) => ({
              ...prevError,
              getLabelNames: { message: 'Failed to fetch trace label names' },
            }));
          });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    {
      timeout: 50,
      ignoreInitialCall: false,
    },
    [customerFilter, showErrorsLandingPage, date],
  );

  useEffect(() => {
    if (isCustomerFilteringEnabled) {
      getServiceGroupLabelRequest.call();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCustomerFilteringEnabled]);

  const selectedLabel = useMemo(() => {
    if (
      getServiceGroupLabelRequest.result &&
      getServiceGroupLabelRequest.result.length
    ) {
      const firstLabel = getServiceGroupLabelRequest.result[0];
      return firstLabel;
    }
    return null;
  }, [getServiceGroupLabelRequest.result]);

  useDebouncedEffect(
    () => {
      if (selectedLabel) {
        getServiceGroupValuesRequest.call({ label: selectedLabel });
      }
    },
    50,
    [selectedLabel],
  );

  const fetchTraceErrors = () => {
    const pageNum = pageNumRef.current || 1;
    traceErrorsRequest
      .call({ customerFilter, isListView: true, pageNum, tracesState })
      .then((nextResult) => {
        if (nextResult && nextResult.length) {
          pageNumRef.current = pageNum + 1;
          setErrorsResult((prevRes) => [...prevRes, ...nextResult]);
          setError((prevError) => ({ ...prevError, getTraces: null }));
        }
      })
      .catch(() => {
        setErrorsResult([]);
        // Generally we follow this pattern for errors setting unless it does not work then useEffect on request.error
        setError((prevError) => ({
          ...prevError,
          getTraces: { message: 'Failed to fetch traces' },
        }));
      });
  };

  const loadInitialErrors = () => {
    pageNumRef.current = null;
    setErrorsResult([]);
    fetchTraceErrors();
  };

  const isTraceErrorsRequestLoading = useMemo(() => {
    return tab === ApmErrorsTab.allErrors && traceErrorsRequest.isLoading;
  }, [traceErrorsRequest.isLoading, tab]);

  const serviceByHash = useMemo(
    () =>
      (getServicesRequest.result || []).reduce(
        (obj, service) => ({ ...obj, [service.hash]: service }),
        {},
      ),
    [getServicesRequest.result],
  );

  const colorsByServiceHash = useMemo(
    () => getColorsByServiceHash(getServicesRequest.result || []),
    [getServicesRequest.result],
  );

  const overlayMessageProps: OverlayMessageProps = error.getTraces
    ? {
        isActive: true,
        iconName: 'warning',
        message: (
          <>
            {error.getTraces?.message || 'Error.'} Please double check your
            query and try again.
          </>
        ),
      }
    : { isActive: false };

  if (isCustomerFilteringConfigLoading) {
    return <Loader className="h-full" isLoading={true} />;
  }

  return (
    <div
      className={classnames({
        'apm-errors': true,
        traces: true,
      })}
    >
      <LeftSidebar leftSidebarState={leftSidebarState}>
        {error?.getLabelNames ? (
          <div className="flex gap-[4px] w-full justify-start pr-[14px] pl-[4px] mt-[4px]">
            <IoIosWarning
              className="overlay-message__icon-and-message__icon"
              size={16}
            />
            <div className="text-red-500">{error?.getLabelNames?.message}</div>
          </div>
        ) : (
          <>
            {isCustomerFilteringEnabled && (
              <CustomerFilter
                customerFilter={customerFilter}
                filterLabel={selectedLabel}
                valueOptions={getServiceGroupValuesRequest.result || []}
                setCustomerFilter={setCustomerFilter}
              />
            )}
            {showErrorsLandingPage && (
              <TracesSidebar
                colorsByServiceHash={colorsByServiceHash}
                customerFilter={customerFilter}
                getLabelValues={errorLabelValues}
                hideDuration
                serviceByHash={serviceByHash}
                traceLabelNamesRequest={traceLabelNamesRequest}
                tracesState={tracesState}
              />
            )}
          </>
        )}
      </LeftSidebar>
      {showErrorsLandingPage ? (
        <div className="traces__main overflow-hidden">
          <div className="traces__header">
            <div className="traces__header__left">
              <TracesSearch
                colorsByServiceHash={colorsByServiceHash}
                isErrorScreen={true}
                leftSidebarState={leftSidebarState}
                placeholder="Search for any tag on trace errors"
                search={search}
                searches={searches}
                serviceByHash={serviceByHash}
                traceLabelNamesRequest={traceLabelNamesRequest}
                tracesState={tracesState}
                tracesTab={TracesTab.list}
              />
              <ApmErrorsVisualize />
            </div>
            <div className="traces__header__right">
              <div className="traces__header__date-controls">
                <div className="traces__header__date-controls__item">
                  <Datepicker
                    onChange={setDate as (dateSelection: DateSelection) => void}
                    value={date as DateSelection}
                  />
                </div>
                <div className="traces__header__date-controls__item">
                  <DateControls date={date} setDate={setDate} />
                </div>
              </div>
            </div>
          </div>
          <TraceErrorChartGrid search={search} tracesState={tracesState} />
          <Loader
            className="traces__table"
            isLoading={isTraceErrorsRequestLoading}
          >
            <div className="traces__table" data-testid="traces-table">
              {tab === ApmErrorsTab.errorGroups ? (
                <ApmErrorsGroupTable
                  colorsByServiceHash={colorsByServiceHash}
                  customerFilter={customerFilter}
                  tracesState={tracesState}
                  serviceByHash={serviceByHash}
                />
              ) : (
                <OverlayMessage {...overlayMessageProps}>
                  <ApmErrorsTable
                    className="table--padded table--bordered-cells"
                    colorsByServiceHash={colorsByServiceHash}
                    loadInitialErrors={loadInitialErrors}
                    serviceByHash={serviceByHash}
                    onScrollEnd={fetchTraceErrors}
                    rows={errorsResult}
                    tracesState={tracesState}
                  />
                </OverlayMessage>
              )}
            </div>
          </Loader>
        </div>
      ) : (
        <div className="flex flex-col height-100-per w-full">
          {leftSidebarState.width === 0 ? (
            <div className="flex justify-start">
              <ShowSidebarTooltipButton
                className="customer-filter__show-sidebar"
                onClick={leftSidebarState.show}
              />
              <div />
            </div>
          ) : null}
          <div className="flex-1 flex items-center justify-center">
            <div className="facet-picker__values__placeholder italic text-2xl">
              Select Service Group Filter To View the Errors
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ApmErrors;
