import {
  RightSidebar,
  useColumnsState,
  useLeftSidebarState,
  useTableOptions,
} from 'components';
import { Datepicker } from 'composite';
import { useRequest } from 'hooks';
import { delimiter } from 'kfuse-constants';
import React, {
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { getWorkbooks } from 'requests';
import LogsSheet from 'screens/Logs/LogsSheet';
import {
  getColumns,
  MESSAGE,
  SOURCE,
  tableColumns,
  TIMESTAMP,
} from 'screens/Logs/constants';
import {
  useLogsState,
  useLogsTable,
  useLogsWorkbooksState,
  useQueryScheduler,
  useQuerySchedulerEffect,
} from 'screens/Logs/hooks';
import { DateSelection, SelectedLog } from 'types';

import { EntityTypes } from '../types';
import LogsSelectedLogTitle from 'screens/Logs/LogsSelectedLogTitle';
import LogsSelectedLog from 'screens/Logs/LogsSelectedLog';
import { getFacetKey, getLogsPrevAndNextHandlers } from 'utils';

type Props = {
  entityType: EntityTypes;
  entity: any;
};

const getKubeFacetKey = (component: string, name: string) => {
  const facetKey = getFacetKey({ component, name, type: 'string' });
  return `${facetKey}${delimiter}`;
};

const KubernetesLogs = ({ entityType, entity }: Props): ReactElement => {
  const getWorkbooksRequest = useRequest(getWorkbooks);
  const tabFetchedRef = useRef({});
  const prevLogsState = useLogsState();
  const logsWorkbooksState = useLogsWorkbooksState({ shouldWriteToUrl: false });
  const { currentLogsState } = logsWorkbooksState;
  const leftSidebarState = useLeftSidebarState('logs');
  const queryScheduler = useQueryScheduler(leftSidebarState);
  const tableOptions = useTableOptions();
  const logsState = { ...prevLogsState, ...currentLogsState };

  const col = getColumns({ customColumnsState: { state: {} }, logsState });
  const columnsState = useColumnsState({
    columns: col,
    initialState: {
      resizedWidths: {},
      selectedColumns: { [MESSAGE]: 1, [SOURCE]: 1, [TIMESTAMP]: 1 },
    },
    key: 'logs-table',
  });

  const bindKeyHandlersRef = useRef<VoidFunction>();
  const [selectedLog, setSelectedLog] = useState<SelectedLog>(null);
  const logsTable = useLogsTable();
  const { appendLogs, generateNewId, id, isLoading, logs, sort, sortByColumn } =
    logsTable;
  const logsRef = useRef([]);
  logsRef.current = logs;

  const {
    filterByFacets,
    filterOrExcludeByFingerprint,
    keyExists,
    date,
    selectedFacetValues,
    searchTerms,
    setDate,
  } = logsState;

  useEffect(() => {
    fetchJsonBasedOnEntity();
    getWorkbooksRequest.call();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchJsonBasedOnEntity = () => {
    const tags = entity.tags;
    const clusterTag = tags.find((tag) => tag.includes('kube_cluster_name'));
    const namespaceTag = tags.find((tag) => tag.includes('kube_namespace'));
    const clusterName = clusterTag ? clusterTag.split(':')[1] : '';
    const kubeClusterName = `${getKubeFacetKey('Cloud', 'kube_cluster_name')}${clusterName}`;
    const namespaceValue = namespaceTag ? namespaceTag.split(':')[1] : '';
    const kubeNamespace = `${getKubeFacetKey('Kubernetes', 'kube_namespace')}${namespaceValue}`;
    switch (entityType) {
      case 'Pod':
        const kubePodName = `${getKubeFacetKey('Kubernetes', 'pod_name')}${entity.metadata.name}`;
        selectedFacetValues[kubeClusterName] = 1;
        selectedFacetValues[kubeNamespace] = 1;
        selectedFacetValues[kubePodName] = 1;
        return;
      case 'Cluster':
        selectedFacetValues[kubeClusterName] = 1;
        return;
      case 'Namespace':
        selectedFacetValues[kubeClusterName] = 1;
        selectedFacetValues[kubeNamespace] = 1;
        return;
      case 'Node':
        const kubeHost = `${getKubeFacetKey('Kubernetes', 'host')}${entity.metadata.name}`;
        selectedFacetValues[kubeHost] = 1;
        return;
      case 'Deployment':
        const kubeDeployment = `${getKubeFacetKey('Additional', 'kube_deployment')}${entity.metadata.name}`;
        selectedFacetValues[kubeClusterName] = 1;
        selectedFacetValues[kubeNamespace] = 1;
        selectedFacetValues[kubeDeployment] = 1;
        return;
      case 'ReplicaSet':
        const kubeReplicaSet = `${getKubeFacetKey('Additional', 'kube_replica_set')}${entity.metadata.name}`;
        selectedFacetValues[kubeClusterName] = 1;
        selectedFacetValues[kubeNamespace] = 1;
        selectedFacetValues[kubeReplicaSet] = 1;
        return;
      case 'Job':
        const kubeJob = `${getKubeFacetKey('Additional', 'kube_job')}${entity.metadata.name}`;
        selectedFacetValues[kubeClusterName] = 1;
        selectedFacetValues[kubeNamespace] = 1;
        selectedFacetValues[kubeJob] = 1;
        return;
      case 'CronJob':
        const kubeCronJob = `${getKubeFacetKey('Additional', 'kube_cronjob')}${entity.metadata.name}`;
        selectedFacetValues[kubeClusterName] = 1;
        selectedFacetValues[kubeNamespace] = 1;
        selectedFacetValues[kubeCronJob] = 1;
        return;
      case 'DaemonSet':
        const kubeDaemonSet = `${getKubeFacetKey('Additional', 'kube_daemon_set')}${entity.metadata.name}`;
        selectedFacetValues[kubeClusterName] = 1;
        selectedFacetValues[kubeNamespace] = 1;
        selectedFacetValues[kubeDaemonSet] = 1;
        return;
      case 'StatefulSet':
        const kubeStatefulSet = `${getKubeFacetKey('Additional', 'kube_stateful_set')}${entity.metadata.name}`;
        selectedFacetValues[kubeClusterName] = 1;
        selectedFacetValues[kubeNamespace] = 1;
        selectedFacetValues[kubeStatefulSet] = 1;
        return;
      case 'Service':
        const kubeService = `${getKubeFacetKey('Kubernetes', 'kube_service')}${entity.metadata.name}`;
        selectedFacetValues[kubeClusterName] = 1;
        selectedFacetValues[kubeNamespace] = 1;
        selectedFacetValues[kubeService] = 1;

      default:
        return;
    }
  };

  const fetchLogs = () => {
    const facetf: any[] = [];
    generateNewId();
    return logsTable.fetchLogs({
      logsState: {
        date,
        selectedFacetValues,
        facetf,
        filterOrExcludeByFingerprint,
        keyExists,
        searchTerms,
        sort,
      },
    });
  };

  const onScrollEnd = () => {
    appendLogs({
      date,
      filterByFacets,
      filterOrExcludeByFingerprint,
      keyExists,
      selectedFacetValues,
      searchTerms,
    });
  };

  useQuerySchedulerEffect({
    cb: fetchLogs,
    logsState,
    queryScheduler,
    sort,
    tabFetchedRef,
    tab: 'logs',
  });

  const columns = useMemo(
    () =>
      tableColumns({
        columnsState,
        customColumnsState: { state: {} },
        logsState,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columnsState.state],
  );

  const onEnter = ({ selection }) => {
    const { initY, startY, endY } = selection;
    const y = initY > startY ? startY : endY;
    setSelectedLog({ index: Math.max(y, 0), logs: logs });
  };

  const { next, prev } = useMemo(
    () => getLogsPrevAndNextHandlers({ logsRef, setSelectedLog }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleCloseSidebar = () => {
    const bindKeyHandlers = bindKeyHandlersRef.current;
    if (bindKeyHandlers) {
      bindKeyHandlers();
    }
    setSelectedLog(null);
  };

  return (
    <div className="kubernetes__tabs__body">
      <div className="flex--justify-content-end mr-2 mt-2 flex">
        <Datepicker
          onChange={setDate as (dateSelection: DateSelection) => void}
          value={date as DateSelection}
        />
      </div>
      <LogsSheet
        bindKeyHandlersRef={bindKeyHandlersRef}
        columns={columns}
        columnsState={columnsState}
        date={date}
        getWorkbooksRequest={getWorkbooksRequest}
        key={id}
        logsState={logsState}
        isLoading={isLoading}
        logs={logs}
        onEnter={onEnter}
        onScrollEnd={onScrollEnd}
        setSelectedLog={setSelectedLog}
        sort={sort}
        sortByColumn={sortByColumn}
        tableOptions={tableOptions}
      />
      {selectedLog && selectedLog.log ? (
        <RightSidebar
          className="logs__right-drawer"
          close={handleCloseSidebar}
          closeOnOutsideClick={handleCloseSidebar}
          title={<LogsSelectedLogTitle logEvent={selectedLog.log} />}
          prev={prev}
          next={next}
        >
          <LogsSelectedLog
            logs={logs}
            logsState={logsState}
            selectedLog={selectedLog}
            prev={prev}
            next={next}
          />
        </RightSidebar>
      ) : null}
    </div>
  );
};

export default KubernetesLogs;
