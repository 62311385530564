import {
  BadgeGroup,
  ConfirmationModal,
  Loader,
  Paginator,
  Table,
  TableSearch,
  TooltipTrigger,
  useModalsContext,
  usePaginator,
  useTableSearch,
  useTableSort,
  useThemeContext,
  useToaster,
} from 'components';
import dayjs from 'dayjs';
import { FilterType, Operator, useRequest } from 'hooks';
import { dateTimeFormat, MILLISECONDS } from 'kfuse-constants';
import React, { useEffect, useState } from 'react';
import { RiExternalLinkLine as ExternalLinkIcon } from 'react-icons/ri';
import { MdOutlineCancel as CancelIcon } from 'react-icons/md';
import { RiFileCopyLine as CopyIcon } from 'react-icons/ri';
import { FaInfoCircle as InfoIcon } from 'react-icons/fa';
import { DateSelection, LogsMetricQueryProps } from 'types';
import {
  convertSecondToReadablePrecision,
  getFacetKey,
  getLogsExplorerDefaultQueryByFilter,
} from 'utils';

import LogsHydrationJobsListJobProgress from './LogsHydrationJobsListJobProgress';
import { cancelJob, getJobsList } from './requests';
import { HydrationJobProps, JobStatus } from './types';
import { colorsByHydrationJobStatus, getHydrationJobDate } from './utils';

const jobsColumns = ({
  onCopyJobId,
  onCancelJob,
  onViewLogs,
  onJobStatusChange,
  utcTimeEnabled,
}: {
  onCopyJobId: (job: HydrationJobProps) => void;
  onCancelJob: (job: HydrationJobProps) => void;
  onViewLogs: (job: HydrationJobProps) => void;
  onJobStatusChange: (job: HydrationJobProps) => void;
  utcTimeEnabled: boolean;
}) => [
  { key: 'ArchiveName', label: 'Archive Name' },
  {
    key: 'ArchivalStartTsMs',
    label: 'Start Time and End Time',
    renderCell: ({ row }: { row: HydrationJobProps }) => {
      const startTs = row.ArchiveStartTsMs / MILLISECONDS;
      const endTs = row.ArchiveEndTsMs / MILLISECONDS;

      const startDayjs = utcTimeEnabled
        ? dayjs.unix(startTs).utc()
        : dayjs.unix(startTs);
      const endDayjs = utcTimeEnabled
        ? dayjs.unix(endTs).utc()
        : dayjs.unix(endTs);

      return (
        <div>
          {startDayjs.format(dateTimeFormat)} -{' '}
          {endDayjs.format(dateTimeFormat)}
        </div>
      );
    },
  },
  {
    key: 'JobStatus',
    label: 'Status',
    renderCell: ({ row }: { row: HydrationJobProps }) => {
      const status = row.JobStatus;
      return (
        <TooltipTrigger
          tooltip={status === JobStatus.FAILED ? row.ErrorMsg : ''}
        >
          <button
            className="kubernetes__table__cell__status flex w-full items-center justify-center px-2 py-1 font-semibold uppercase"
            style={{
              color: colorsByHydrationJobStatus[status],
            }}
          >
            {status}
            {status === JobStatus.FAILED && (
              <InfoIcon
                size={14}
                className="ml-1 cursor-pointer text-red-500"
              />
            )}
          </button>
        </TooltipTrigger>
      );
    },
  },
  {
    key: 'JobProgress',
    label: 'Progress',
    renderCell: ({ row }: { row: HydrationJobProps }) => (
      <LogsHydrationJobsListJobProgress
        job={row}
        onJobStatusChange={onJobStatusChange}
      />
    ),
  },
  {
    key: 'filter',
    label: 'Filters',
    renderCell: ({ row }: { row: HydrationJobProps }) => {
      if (!row.ArchiveFilters) return;
      const filtersJson = JSON.parse(row.ArchiveFilters);
      const filterKeys = Object.keys(filtersJson).map(
        (key) => `${key}:${filtersJson[key]}`,
      );
      return (
        <div className="min-w-[230px] max-w-[230px]">
          <BadgeGroup
            badgeList={filterKeys}
            contractList={true}
            renderPopoverOnBadgeClick={null}
          />
        </div>
      );
    },
  },
  {
    key: 'tags',
    label: 'Tags',
    renderCell: ({ row }: { row: HydrationJobProps }) => {
      if (!row.ArchiveAddTags) return;
      const tagsJson = JSON.parse(row.ArchiveAddTags);
      const tagKeys = Object.keys(tagsJson).map(
        (key) => `${key}:${tagsJson[key]}`,
      );
      return (
        <div className="min-w-[230px] max-w-[230px]">
          <BadgeGroup
            badgeList={tagKeys}
            contractList={true}
            renderPopoverOnBadgeClick={null}
          />
        </div>
      );
    },
  },
  {
    key: 'CreatedAtMs',
    label: 'Created At',
    renderCell: ({ row }: { row: HydrationJobProps }) => {
      const createdAt = row.CreatedAtMs / MILLISECONDS;
      const createdAtDayjs = utcTimeEnabled
        ? dayjs.unix(createdAt).utc()
        : dayjs.unix(createdAt);
      return <div>{createdAtDayjs.format(dateTimeFormat)}</div>;
    },
  },
  {
    key: 'DurationMs',
    label: 'Retention',
    renderCell: ({ row }: { row: HydrationJobProps }) => {
      const durationSeconds = row.ArchiveDurationMs / 1000;
      return (
        <div>{convertSecondToReadablePrecision(durationSeconds, true)}</div>
      );
    },
  },
  {
    label: '',
    key: 'actions',
    renderCell: ({ row }: { row: HydrationJobProps }) => {
      const isRunning =
        row.JobStatus === JobStatus.HYDRATING ||
        row.JobStatus === JobStatus.INDEXING ||
        row.JobStatus === JobStatus.INIT;
      return (
        <div
          className="table__row__actions"
          style={{ '--table-actions-width': isRunning ? '109px' : '73px' }}
        >
          <div className="table__row__actions--hidden">
            <div className="table__row__actions__slider">
              <div className="alerts__contacts__table__actions">
                <div
                  className="table__cell__actions__item--blue"
                  onClick={() => onCopyJobId(row)}
                >
                  <TooltipTrigger tooltip="Copy Job ID">
                    <CopyIcon
                      className="alerts__contacts__table__actions__icon--blue"
                      size={18}
                    />
                  </TooltipTrigger>
                </div>
                <div
                  className="table__cell__actions__item--blue"
                  onClick={() => onViewLogs(row)}
                >
                  <TooltipTrigger tooltip="View Logs">
                    <ExternalLinkIcon
                      className="alerts__contacts__table__actions__icon--blue"
                      size={18}
                    />
                  </TooltipTrigger>
                </div>
                {isRunning && (
                  <div
                    className="table__cell__actions__item--red"
                    onClick={() => onCancelJob(row)}
                  >
                    <TooltipTrigger tooltip="Cancel Job">
                      <CancelIcon
                        className="alerts__contacts__table__actions__icon--delete"
                        size={18}
                      />
                    </TooltipTrigger>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    },
  },
];

const LogsHydrationJobsList = ({ date }: { date: DateSelection }) => {
  const { addToast } = useToaster();
  const modal = useModalsContext();
  const [jobList, setJobList] = useState<HydrationJobProps[]>([]);
  const { utcTimeEnabled } = useThemeContext();
  const getJobsListRequest = useRequest(getJobsList);
  const cancelJobRequest = useRequest(cancelJob);

  useEffect(() => {
    getJobsListRequest.call(date).then((res) => {
      setJobList(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const onCancelJob = (row: HydrationJobProps) => {
    modal.push(
      <ConfirmationModal
        className="alerts__list__delete-alerts-rule"
        description={
          <span>
            Are you sure you want to cancel{' '}
            <span className="font-bold">{row.JobId}</span> job?
          </span>
        }
        dataTestId="delete-group-confirmation-modal"
        onCancel={() => modal.pop()}
        cancelText="Close"
        submitText="Cancel Job"
        onConfirm={() => {
          cancelJobRequest.call(row.JobId).then((res) => {
            modal.pop();
            addToast({ text: res.message, status: 'success' });

            getJobsListRequest.call(date);
          });
        }}
        title="Cancel Job"
      />,
    );
  };

  const onViewLogs = (row: HydrationJobProps) => {
    const facetKey = getFacetKey({
      component: 'Additional',
      name: '__kf_hydration_job',
      type: 'string',
      displayName: '',
    });
    const filters: LogsMetricQueryProps['filters'] = [
      {
        type: FilterType.selectedFacetValue,
        value: {
          facet: facetKey,
          operator: Operator.equal,
          values: { [row.JobId]: 1 },
        },
      },
    ];
    const defaultLogsQuery = getLogsExplorerDefaultQueryByFilter(filters);
    const encodedLogsQuery = encodeURIComponent(
      JSON.stringify([defaultLogsQuery]),
    );

    const date = getHydrationJobDate(row);
    const encodedDate = encodeURIComponent(JSON.stringify(date));
    const url = `#/logs?logsDate=${encodedDate}&LogsMetricsQueries=${encodedLogsQuery}`;
    window.open(url, '_blank');
  };

  const onCopyJobId = (row: HydrationJobProps) => {
    navigator.clipboard.writeText(row.JobId);
    addToast({ text: 'Job ID copied to clipboard', status: 'success' });
  };

  const onJobStatusChange = (job: HydrationJobProps) => {
    setJobList((prev) => {
      const newState = [...prev];
      const index = newState.findIndex((j) => j.JobId === job.JobId);
      if (index !== -1) {
        newState[index] = job;
      }
      return newState;
    });
  };

  const columns = jobsColumns({
    onCancelJob,
    onViewLogs,
    onCopyJobId,
    onJobStatusChange,
    utcTimeEnabled,
  });
  const jobs = jobList;
  const tableSearch = useTableSearch({ rows: jobs });
  const tableSort = useTableSort({ columns, rows: tableSearch.searchedRows });
  const paginator = usePaginator({
    key: 'logs-hydration-jobs',
    rows: tableSort.sortedRows,
    initialNumberOfRowsPerPage: 25,
  });

  return (
    <div className="pt-4">
      <TableSearch
        className="dashboard__list__search"
        placeholder="Search Jobs..."
        tableSearch={tableSearch}
        dataTestId="dashboard-list-search"
      />
      <Loader isLoading={getJobsListRequest.isLoading}>
        <Table
          columns={columns}
          rows={paginator.paginatedRows}
          className="table--bordered table--bordered-cells table__actions--hidden alerts__list__table"
          dataTestId="alerts-list-table"
          externalTableSort={tableSort}
          isSortingEnabled
        />
        <div className="table-footer">
          <Paginator paginator={paginator} />
        </div>
      </Loader>
    </div>
  );
};

export default LogsHydrationJobsList;
