import { FilterType, Operator } from 'hooks';
import { delimiter } from 'kfuse-constants';
import {
  defaultLogsQuery,
  getFacetKey,
  getIsLogRangeFacet,
  getLogQLWithMetaToLoad,
} from 'utils';
import { DashboardPanelType, DateSelection, LogsMetricQueryProps } from 'types';

import { FavoriteFacetExtend, LogsQueryForGraphQLProps } from './types';

export const getSelectedValuesPipeline = (selectedValues: string[]) => {
  if (selectedValues.length === 0) {
    return '.+';
  }
  return selectedValues.join('|');
};

export const getAnalyticsQuery = ({
  facet,
  selectedValues,
  type,
}: {
  facet: FavoriteFacetExtend;
  selectedValues: string[];
  type: 'load' | 'new_tab';
}): LogsMetricQueryProps => {
  const isFacetNumeric = getIsLogRangeFacet(facet.type);
  const query = { ...defaultLogsQuery };

  if (facet.type === 'DURATION') {
    query.normalizeFunction = 'duration';
  } else if (facet.type === 'SIZE') {
    query.normalizeFunction = 'bytes';
  } else {
    query.normalizeFunction = 'number';
  }

  const facetKey = getFacetKey({
    component: facet.source,
    name: `${facet.name}`,
    type: facet.type,
    displayName: facet.displayName,
  });

  if (isFacetNumeric) {
    query.metric = `@${facet.name}${delimiter}${facet.type}`;
    query.rangeAggregate = 'avg_over_time';
    query.vectorAggregate = 'avg';
  } else {
    query.vectorAggregate = 'sum';
  }

  const pipelineValues = getSelectedValuesPipeline(selectedValues);
  if (type === 'load') {
    const filterBySource = `Core${delimiter}source${delimiter}string`;
    query.filters = {
      sidebarFilters: { [filterBySource]: { [facet.source]: 1 } },
      filterByFacets: [
        `${facet.type}${delimiter}@${facet.name}=~"${pipelineValues}"`,
      ],
    };
  }

  if (type === 'new_tab') {
    const filters = getFacetExplorerFilter(facet, selectedValues);
    query.filters = filters;
    query.defaultRangeAggregateGroupingOptions = [
      { label: `@${facet.displayName || facet.name}`, value: facetKey },
    ];
  }
  return query;
};

export const getFacetExplorerFilter = (
  facet: FavoriteFacetExtend,
  selectedValues: string[],
): LogsMetricQueryProps['filters'] => {
  const facetKey = getFacetKey({
    component: '',
    name: `@${facet.name}`,
    type: facet.type,
    displayName: `@${facet.displayName}`,
  });

  const pipelineValues = getSelectedValuesPipeline(selectedValues);
  const facetSourceKey = getFacetKey({
    component: 'Core',
    name: 'source',
    type: 'string',
  });

  const filters: LogsMetricQueryProps['filters'] = [
    {
      type: FilterType.selectedFacetValue,
      value: {
        facet: facetSourceKey,
        operator: Operator.equal,
        values: { [facet.source]: 1 },
      },
    },
  ];

  if (selectedValues.length === 0) {
    const keyExistsFacetKey = getFacetKey({ ...facet, component: '' });
    filters.push({
      type: FilterType.keyExists,
      value: { facet: keyExistsFacetKey, operator: Operator.equal },
    });
  } else {
    filters.push({
      type: FilterType.selectedFacetValue,
      value: {
        facet: facetKey,
        operator: Operator.regex,
        values: { [pipelineValues]: 1 },
      },
    });
  }

  return filters;
};

export const getFacetExplorerGraphqlQuery = ({
  facet,
  date,
  selectedValues,
}: {
  facet: FavoriteFacetExtend;
  date: DateSelection;
  selectedValues: string[];
}): LogsQueryForGraphQLProps => {
  const baseQuery = getAnalyticsQuery({ facet, selectedValues, type: 'load' });
  const instantLogQLWithMeta = getLogQLWithMetaToLoad({
    dataFormat: DashboardPanelType.TIMESERIES,
    date,
    formulas: [],
    formulaOnly: false,
    instant: false,
    queries: [baseQuery],
  });

  const isNumericFacet = getIsLogRangeFacet(facet.type);
  return {
    meta: instantLogQLWithMeta[0].meta,
    query: baseQuery,
    facetName: isNumericFacet ? facet.name : undefined,
    unit: instantLogQLWithMeta?.[0]?.meta?.unit || 'number',
    normalizeFunction:
      baseQuery.normalizeFunction === 'number'
        ? undefined
        : baseQuery.normalizeFunction,
  };
};

const getAnalyticsQueriesForNumericFacet = (
  facet: FavoriteFacetExtend,
  date: DateSelection,
  selectedValues: string[],
): LogsQueryForGraphQLProps[] => {
  const queries: LogsMetricQueryProps[] = [];
  const baseQuery = getAnalyticsQuery({ facet, selectedValues, type: 'load' });

  // avg over time
  queries.push(baseQuery);

  // sum over time
  queries.push({
    ...baseQuery,
    rangeAggregate: 'sum_over_time',
    vectorAggregate: 'sum',
    queryKey: 'b',
  });

  // min over time
  queries.push({
    ...baseQuery,
    rangeAggregate: 'min_over_time',
    vectorAggregate: 'min',
    queryKey: 'c',
  });

  // max over time
  queries.push({
    ...baseQuery,
    rangeAggregate: 'max_over_time',
    vectorAggregate: 'max',
    queryKey: 'd',
  });

  let instantLogQLWithMeta = getLogQLWithMetaToLoad({
    dataFormat: DashboardPanelType.TIMESERIES,
    date,
    formulas: [],
    formulaOnly: false,
    instant: true,
    queries: queries,
  });

  const getLegendFormat = (vectorAggregate: string) => {
    if (vectorAggregate === 'avg') {
      return 'Avg';
    }
    if (vectorAggregate === 'sum') {
      return 'Sum';
    }
    if (vectorAggregate === 'min') {
      return 'Min';
    }
    if (vectorAggregate === 'max') {
      return 'Max';
    }
  };

  instantLogQLWithMeta = instantLogQLWithMeta.map((query) => {
    return {
      ...query,
      meta: {
        ...query.meta,
        legendFormat: getLegendFormat(query.meta.metricName),
      },
    };
  });

  const logsQueryForGraphQL: LogsQueryForGraphQLProps[] = [];
  const isNumericFacet = getIsLogRangeFacet(facet.type);
  instantLogQLWithMeta.forEach((logQLWithMeta, idx) => {
    const query = queries[idx];
    logsQueryForGraphQL.push({
      query,
      meta: logQLWithMeta.meta,
      facetName: isNumericFacet ? facet.name : undefined,
      unit: logQLWithMeta?.meta?.unit || 'number',
      normalizeFunction:
        query.normalizeFunction === 'number'
          ? undefined
          : query.normalizeFunction,
    });
  });

  return logsQueryForGraphQL;
};

export const getAnalyticsLogQLWithMetaToLoad = (
  facet: FavoriteFacetExtend,
  date: DateSelection,
  selectedValues: string[],
): LogsQueryForGraphQLProps[] => {
  const { type } = facet;
  const isNumericFacet = getIsLogRangeFacet(type);
  if (isNumericFacet) {
    return getAnalyticsQueriesForNumericFacet(facet, date, selectedValues);
  }

  const baseQuery = getAnalyticsQuery({ facet, selectedValues, type: 'load' });
  baseQuery.metric = `@${facet.name}${delimiter}${facet.type}`;
  let instantLogQLWithMeta = getLogQLWithMetaToLoad({
    dataFormat: DashboardPanelType.TIMESERIES,
    date,
    formulas: [],
    formulaOnly: false,
    instant: true,
    queries: [baseQuery],
  });
  instantLogQLWithMeta = instantLogQLWithMeta.map((query) => {
    return {
      ...query,
      meta: { ...query.meta, legendFormat: 'Count unique' },
    };
  });

  return [
    {
      query: baseQuery,
      meta: instantLogQLWithMeta[0].meta,
      facetName: isNumericFacet ? facet.name : undefined,
      unit: instantLogQLWithMeta?.[0]?.meta?.unit || 'number',
      normalizeFunction:
        baseQuery.normalizeFunction === 'number'
          ? undefined
          : baseQuery.normalizeFunction,
    },
  ];
};

export const getFacetExplorerLogStateFilter = ({
  facet,
  selectedValues,
  date,
  selectedSource,
}: {
  facet: FavoriteFacetExtend;
  selectedValues: string[];
  date: DateSelection;
  selectedSource: string;
}) => {
  const facetKey = getFacetKey({
    ...facet,
    component: selectedSource,
  });
  const facetSource = `Core${delimiter}source${delimiter}string${delimiter}`;
  const query = {
    date,
    keyExists: {},
    selectedFacetValues: {
      [`${facetSource}${delimiter}${facet.source}`]: 1,
    },
  };

  if (selectedValues.length > 0) {
    selectedValues.forEach((value) => {
      query.selectedFacetValues[`${facetKey}${delimiter}${value}`] = 1;
    });
  } else {
    query.keyExists = { [facetKey]: 1 };
  }

  return query;
};
