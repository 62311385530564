import classNames from 'classnames';
import { Button, Search, TooltipTrigger } from 'components';
import { SearchState, useSearches, useSearchFormulas } from 'hooks/useSearch';
import useTracesState from 'hooks/useTracesState';
import { numericOperatorBitmap } from 'kfuse-constants/search';
import React, { useCallback, useEffect, useMemo } from 'react';
import { XCircle } from 'react-feather';
import { DashboardPanelType, Service } from 'types';
import { TracesTab } from 'types/TracesTab';
import getIsHcFacetName from 'utils/getIsHcFacetName';
import { parsePartialSearchQuery } from 'utils/SearchBar';
import { ALLOW_ONLY_ONE_ACTIVE_SEARCH, areFiltersEqual } from '../utils';
import getTracesTags from './getTracesTags';
import TracesSearchGrouper from './TracesSearchGrouper';
import TracesSearchInputPanel from './TracesSearchInputPanel';

type Props = {
  colorsByServiceHash: Record<string, string>;
  getRange: (args: Record<string, unknown>) => void;
  groupByOptions: any;
  index: number;
  labelNames: Array<any>;
  measureOptions: Array<any>;
  placeholder: string;
  search: SearchState & { selectOnlySingeQuery: () => void };
  searches: ReturnType<typeof useSearches>;
  searchesFormulas: ReturnType<typeof useSearchFormulas>;
  serviceByHash: Record<string, Service>;
  tracesTab: TracesTab;
  tracesState: ReturnType<typeof useTracesState>;
};

const TracesQuerySearch = ({
  colorsByServiceHash,
  getRange,
  groupByOptions,
  index,
  labelNames,
  measureOptions,
  placeholder,
  search,
  searches,
  searchesFormulas,
  serviceByHash,
  tracesTab,
  tracesState: pageTracesState,
}: Props) => {
  const { searchBarState } = search;
  const queryTraceState = useTracesState({
    initialFilters: searchBarState?.filters,
    initialTraceIdSearch: searchBarState?.traceIdSearch,
    shouldWriteToUrl: false,
  });
  const {
    dateState,
    facetRegexState,
    filtersState,
    keyExistsState,
    selectedFacetRangeByNameState,
    selectedFacetValuesByNameState,
    selectedHcFacetValuesByNameState,
    setTraceIdSearch,
    spanFilters,
    traceIdSearch,
  } = queryTraceState;
  const [date] = dateState;
  const { spanFilter } = spanFilters;

  const shouldUpdateQueryStateToPageState = useMemo(() => {
    const selectedIndex = pageTracesState.selectedQueryIndexString || '0';
    return index === Number(selectedIndex);
  }, [index, pageTracesState.selectedQueryIndexString]);

  const handleSetTraceIdSearch = (value: string) => {
    setTraceIdSearch(value);
    if (shouldUpdateQueryStateToPageState) {
      pageTracesState.setTraceIdSearch(value);
    }
  };

  const keyExistsStateWithPageStateHandler = useMemo(
    () => {
      return {
        ...keyExistsState,
        setKeyExists: (value: string) => {
          keyExistsState.setKeyExists(value);
          if (shouldUpdateQueryStateToPageState) {
            pageTracesState.keyExistsState.setKeyExists(value);
          }
        },
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [keyExistsState.state, shouldUpdateQueryStateToPageState],
  );

  const filtersStateWithPageStateHandler = useMemo(
    () => {
      return {
        ...filtersState,
        deleteByIndex: (i: number) => {
          filtersState.deleteByIndex(i);
          if (shouldUpdateQueryStateToPageState) {
            pageTracesState.filtersState.deleteByIndex(i);
          }
        },
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filtersState.state, shouldUpdateQueryStateToPageState],
  );

  const tags = useMemo(
    () => {
      return getTracesTags({
        colorsByServiceHash,
        filtersState: filtersStateWithPageStateHandler,
        serviceByHash,
        setTraceIdSearch: handleSetTraceIdSearch,
        traceIdSearch,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filtersStateWithPageStateHandler, queryTraceState.state, serviceByHash],
  );

  //To update QueryBuilder Search State with Main Page Search State if selected index is same
  useEffect(() => {
    const selectedIndex = pageTracesState.selectedQueryIndexString || '0';
    if (index === Number(selectedIndex)) {
      const areFiltersSame = areFiltersEqual({
        filters1: pageTracesState.filtersState.state,
        filters2: queryTraceState.filtersState.state,
      });

      const areTraceIdSearchSame =
        pageTracesState.traceIdSearch === queryTraceState.traceIdSearch;

      if (!areFiltersSame || !areTraceIdSearchSame) {
        queryTraceState.filtersState.setState(
          pageTracesState.filtersState.state,
        );
        queryTraceState.setTraceIdSearch(pageTracesState.traceIdSearch);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageTracesState.state]);

  useEffect(() => {
    search.setSearchBarState({
      filters: filtersState.state,
      traceIdSearch: traceIdSearch,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryTraceState.state]);

  const handleRemoveSearch = () => {
    if (shouldUpdateQueryStateToPageState) {
      pageTracesState.setSelectedQueryIndexString('0');
    }
    search?.removeExistingSearch(index);
  };

  const onEnter = ({ typed }) => {
    const parsedSearch = parsePartialSearchQuery(typed, false);
    if (parsedSearch) {
      const {
        facetName: originalFacetName,
        operator,
        operatorIndex,
        value,
      } = parsedSearch;
      const facetName =
        originalFacetName === 'service' ? 'service_hash' : originalFacetName;
      if (facetName && operator) {
        if (operator === '=' || operator === '!=') {
          if (facetName === 'key exists') {
            keyExistsStateWithPageStateHandler.setKeyExists(value);
            return;
          }

          const setFacetValues = getIsHcFacetName(facetName)
            ? selectedHcFacetValuesByNameState.setFacetValues
            : selectedFacetValuesByNameState.setFacetValues;

          const values = value
            .split(' OR ')
            .map((v) => v.trim())
            .filter((v) => v);

          setFacetValues({
            name: facetName,
            facetValues: values.reduce(
              (obj, value) => ({
                ...obj,
                [value]: operator === '=' ? 1 : 0,
              }),
              {},
            ),
          });

          if (shouldUpdateQueryStateToPageState) {
            const setFacetValuesForPage = getIsHcFacetName(facetName)
              ? pageTracesState.selectedHcFacetValuesByNameState.setFacetValues
              : pageTracesState.selectedFacetValuesByNameState.setFacetValues;

            setFacetValuesForPage({
              name: facetName,
              facetValues: values.reduce(
                (obj, value) => ({
                  ...obj,
                  [value]: operator === '=' ? 1 : 0,
                }),
                {},
              ),
            });
          }
          return;
        }

        if (numericOperatorBitmap[operator]) {
          const { facetName: rangeFacetName, ...range } = getRange({
            firstOperator: operator,
            firstOperatorIndex: operatorIndex,
            typed,
          });

          selectedFacetRangeByNameState.changeFacetRange({
            name: rangeFacetName,
          })(range);

          if (shouldUpdateQueryStateToPageState) {
            pageTracesState.selectedFacetRangeByNameState.changeFacetRange({
              name: rangeFacetName,
            })(range);
          }
          return;
        }

        if (operator === '!~' || operator === '=~') {
          facetRegexState.add({
            isEqual: operator === '=~',
            name: facetName,
            value,
          });
          if (shouldUpdateQueryStateToPageState) {
            pageTracesState.facetRegexState.add({
              isEqual: operator === '=~',
              name: facetName,
              value,
            });
          }
          return;
        }
      }
    }

    handleSetTraceIdSearch(typed);
  };

  const { changeActive } = search;

  const handleActiveClick = useCallback(() => {
    if (
      ALLOW_ONLY_ONE_ACTIVE_SEARCH.includes(
        tracesTab as unknown as DashboardPanelType,
      )
    ) {
      if (search.isActive) {
        changeActive(!search.isActive);
      } else {
        search.selectOnlySingeQuery();
        const countOfFormulas = searchesFormulas?.formulas?.filter(
          (f) => f.state.isActive,
        )?.length;
        if (countOfFormulas > 0) {
          searchesFormulas?.deactivateAll();
        }
      }
    } else {
      changeActive(!search.isActive);
    }
  }, [changeActive, search, searchesFormulas, tracesTab]);

  return (
    <>
      <div className="flex--direction-col mb-3 flex">
        <div className="flex">
          <div className="width-100-per flex">
            {
              <TooltipTrigger tooltip={'Show Chart'}>
                <div
                  onClick={handleActiveClick}
                  className={classNames({
                    'query-builder__logs__item__query-key': true,
                    'query-builder__logs__item__query-key--fullHeight': true,
                    'query-builder__logs__item__query-key--inactive':
                      !search.isActive,
                  })}
                >
                  {search.queryKey}
                </div>
              </TooltipTrigger>
            }
            <Search
              hideInfo
              onEnter={onEnter}
              placeholder={placeholder}
              renderTypedPanel={({
                close,
                editIndex,
                focus,
                onValueSelect,
                setTyped,
                typed,
              }) => {
                return (
                  <TracesSearchInputPanel
                    close={close}
                    customerFilter={pageTracesState.customerFilter}
                    colorsByServiceHash={colorsByServiceHash}
                    date={date}
                    editIndex={editIndex}
                    focus={focus}
                    keyExistsState={keyExistsStateWithPageStateHandler}
                    labelNames={labelNames}
                    onEnter={onEnter}
                    onValueSelect={onValueSelect}
                    selectedFacetValuesByNameState={
                      selectedFacetValuesByNameState
                    }
                    serviceByHash={serviceByHash}
                    setTyped={setTyped}
                    spanFilter={spanFilter}
                    tags={tags}
                    typed={typed}
                  />
                );
              }}
              tags={tags}
            />
            {Boolean(searches.length > 1 && index !== 0) && (
              <div className="trace__analytics__query-action">
                <TooltipTrigger tooltip="Remove Query">
                  <Button
                    className="trace__analytics__query-action--delete h-full px-1.5"
                    variant="icon"
                    size="sm"
                  >
                    <XCircle onClick={handleRemoveSearch} size={16} />
                  </Button>
                </TooltipTrigger>
              </div>
            )}
          </div>
        </div>
        <TracesSearchGrouper
          key={index}
          date={date}
          groupByOptions={groupByOptions}
          measureOptions={measureOptions}
          search={search}
          searchIndex={index}
          searchesCount={searches.length}
          tracesTab={tracesTab}
        />
      </div>
    </>
  );
};

export default TracesQuerySearch;
