import {
  Loader,
  Table,
  TooltipTrigger,
  TableSearch,
  useTableSearch,
} from 'components';
import React, { ReactElement } from 'react';
import { convertNumberToReadableUnit } from 'utils/formatNumber';

import useTracesCardinality from './useTracesCardinality';
import MetricsCardinalityBarChart from '../MetricsCardinality/MetricsCardinalityBarChart';

const columns = ({
  isValueChartLoading,
  isValueOneHourLoading,
}: {
  isValueChartLoading: boolean;
  isValueOneHourLoading: boolean;
}) => [
  {
    key: 'Attribute',
    label: 'Label',
    renderCell: ({ row }: { row: any }) => row.GroupVal['key'],
  },
  {
    key: 'valueOneHour',
    label: 'Value Count (1h)',
    renderCell: ({ row }: { row: any }) => {
      if (isValueOneHourLoading) return <div className="spinner" />;
      return (
        <TooltipTrigger tooltip={row.Value > 1000 ? row.Value : ''}>
          {convertNumberToReadableUnit(row.valueOneHour)}
        </TooltipTrigger>
      );
    },
  },
  {
    key: 'valueCount',
    label: 'Value Count',
    renderCell: ({ row }: { row: any }) => {
      return (
        <TooltipTrigger tooltip={row.Value > 1000 ? row.Value : ''}>
          {convertNumberToReadableUnit(row.Value)}
        </TooltipTrigger>
      );
    },
  },
  {
    key: 'valueChart',
    label: 'Value Chart',
    renderCell: ({ row }: { row: any }) => {
      if (isValueChartLoading) return <div className="spinner" />;
      return <MetricsCardinalityBarChart data={row.valueSeries || []} />;
    },
  },
];

const TracesCardinalityTable = ({
  tracesCardinality,
}: {
  tracesCardinality: ReturnType<typeof useTracesCardinality>;
}): ReactElement => {
  const {
    overallTraceCardinalityRequest,
    tracesCardinalityRows,
    traceCardinalityRequest,
    traceCardinalityRequestOneHour,
    traceCardinalityChartRequest,
  } = tracesCardinality;

  const tableSearch = useTableSearch({
    rows: tracesCardinalityRows,
    shouldWriteToUrl: false,
  });

  return (
    <>
      <div className=" text--h3 pt-4" data-testid="all-cardinality-label">
        All cardinality: {overallTraceCardinalityRequest.result || ''}
      </div>
      <Loader
        className="mb-8 mt-2"
        isLoading={traceCardinalityRequest.isLoading}
      >
        <TableSearch
          className="uplot__value-legends__search-bar"
          placeholder="Search labels..."
          tableSearch={tableSearch}
          dataTestId="label-search-bar"
        />
        <Table
          className="table--bordered table--bordered-cells metrics-cardinality-table"
          columns={columns({
            isValueChartLoading: traceCardinalityChartRequest.isLoading,
            isValueOneHourLoading: traceCardinalityRequestOneHour.isLoading,
          })}
          rows={tableSearch.searchedRows}
          dataTestId="traces-cardinality-table"
        />
      </Loader>
    </>
  );
};

export default TracesCardinalityTable;
