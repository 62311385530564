import {
  Badge,
  Button,
  Loader,
  Paginator,
  Table,
  TableSearch,
  TooltipTrigger,
  useModalsContext,
  usePaginator,
  useTableSearch,
  useTableSort,
  useThemeContext,
  useToaster,
} from 'components';
import dayjs from 'dayjs';
import { useRequest } from 'hooks';
import { dateTimeFormat, MILLISECONDS } from 'kfuse-constants';
import React, { useEffect, useState } from 'react';
import { findUnitCategoryFormatById, getColorRandom } from 'utils';

import LogsHydrationArchivesHydrate from './LogsHydrationArchivesHydrate';
import { getArchivesList } from './requests';
import { ArchiveInfoProps } from './types';

const archivesColumns = ({
  onHydrate,
  utcTimeEnabled,
}: {
  onHydrate: (archive: ArchiveInfoProps) => void;
  utcTimeEnabled: boolean;
}) => [
  {
    key: 'FirstArchivedRecordUTCTsMs',
    label: 'First Archived Record (UTC)',
    renderCell: ({ row }: { row: ArchiveInfoProps }) => {
      const timestamp = row.FirstArchivedRecordUTCTsMs / MILLISECONDS;
      const timestampDayjs = dayjs.unix(timestamp).utc();
      return <div>{timestampDayjs.format(dateTimeFormat)}</div>;
    },
  },
  {
    key: 'FirstObjectCreatedUTCTsMs',
    label: 'First Object Created (UTC)',
    renderCell: ({ row }: { row: ArchiveInfoProps }) => {
      const timestamp = row.FirstObjectCreatedUTCTsMs / MILLISECONDS;
      const timestampDayjs = dayjs.unix(timestamp).utc();
      return <div>{timestampDayjs.format(dateTimeFormat)}</div>;
    },
  },
  { key: 'name', label: 'Name' },
  {
    key: 'approxSizeBytes',
    label: 'Approx Size',
    renderCell: ({ row }: { row: ArchiveInfoProps }) => {
      const formatValue = findUnitCategoryFormatById('bytes');
      const formattedValue = formatValue.fn(row.approxSizeBytes);
      return <div>{`${formattedValue.text}${formattedValue.suffix}`}</div>;
    },
  },
  {
    key: 'location',
    label: 'Location',
    renderCell: ({ row }: { row: ArchiveInfoProps }) => (
      <TooltipTrigger tooltip={row.location}>
        <div className="max-w-[360px] truncate">{row.location}</div>
      </TooltipTrigger>
    ),
  },
  {
    key: 'filters',
    label: 'Filters',
    renderCell: ({ row }: { row: ArchiveInfoProps }) => {
      if (!row.filters) return;
      return row.filters.map((filter) => {
        return (
          <Badge
            key={`${filter.Matcher}-${filter.Operator}-${filter.Value}`}
            className="py-0.25 mb-1 mr-1 truncate rounded-sm px-1 text-xs font-medium text-white"
            variant="outline"
            style={{ backgroundColor: getColorRandom() }}
          >
            {filter.Matcher}
            <span className="mx-1 text-text-secondary">{filter.Operator}</span>
            {filter.Value}
          </Badge>
        );
      });
    },
  },
  {
    key: 'actions',
    label: '',
    renderCell: ({ row }: { row: ArchiveInfoProps }) => {
      return (
        <div
          className="table__row__actions"
          style={{ '--table-actions-width': '80px' }}
        >
          <div className="table__row__actions--hidden">
            <div className="table__row__actions__slider table__row__actions__slider--no-color">
              <div className="alerts__contacts__table__actions">
                <div className="mr-1.5 flex items-center py-1">
                  <Button
                    variant="default"
                    size="sm"
                    onClick={() => onHydrate(row)}
                  >
                    Hydrate
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    },
  },
];

const emptyArr = [];
const LogsHydrationArchives = () => {
  const { addToast } = useToaster();
  const { utcTimeEnabled, toggleUtcTime } = useThemeContext();
  const getArchivesListRequest = useRequest(getArchivesList);
  const modal = useModalsContext();
  const [utcTimeEnabledDefault, setUtcTimeEnabledDefault] = useState(undefined);

  useEffect(() => {
    getArchivesListRequest.call();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onHydrate = (archive: ArchiveInfoProps) => {
    if (!utcTimeEnabled) {
      toggleUtcTime();
    }
    setUtcTimeEnabledDefault(utcTimeEnabled);
    modal.push(
      <LogsHydrationArchivesHydrate
        archive={archive}
        close={() => modal.pop()}
        onSuccess={(jobId: string) => {
          addToast({
            text: `Archive hydration initiated. Job ID: ${jobId}`,
            status: 'success',
          });
        }}
      />,
    );
  };

  const columns = archivesColumns({ onHydrate, utcTimeEnabled });
  const archives = getArchivesListRequest.result || emptyArr;
  const tableSearch = useTableSearch({ rows: archives });
  const tableSort = useTableSort({ columns, rows: tableSearch.searchedRows });
  const paginator = usePaginator({
    key: 'logs-hydration-archives',
    rows: tableSort.sortedRows,
    initialNumberOfRowsPerPage: 25,
  });

  useEffect(() => {
    if (modal.stack.length === 0 && utcTimeEnabledDefault !== undefined) {
      toggleUtcTime();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal.stack, utcTimeEnabledDefault]);

  return (
    <div className="pt-4">
      <TableSearch
        className="dashboard__list__search"
        placeholder="Search Archives..."
        tableSearch={tableSearch}
        dataTestId="dashboard-list-search"
      />
      <Loader isLoading={getArchivesListRequest.isLoading}>
        <Table
          columns={columns}
          rows={paginator.paginatedRows}
          className="table--bordered table--bordered-cells table__actions--hidden alerts__list__table"
          dataTestId="alerts-list-table"
          externalTableSort={tableSort}
          isSortingEnabled
        />
        <div className="table-footer">
          <Paginator paginator={paginator} />
        </div>
      </Loader>
    </div>
  );
};

export default LogsHydrationArchives;
