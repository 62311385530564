import React, { useMemo } from 'react';
import { AutocompleteV2 } from 'components';

const getOptionVal = (option: string) => {
  switch (option) {
    case 'None':
      return null;
    default:
      return option;
  }
};
const CustomerFilter = ({
  customerFilter,
  filterLabel,
  valueOptions,
  setCustomerFilter,
}: {
  customerFilter: { key: string; value: string };
  filterLabel: string;
  valueOptions: string[];
  setCustomerFilter: ({ key, value }: { key: string; value: string }) => void;
}) => {
  const optionsWithAllAndNone = useMemo(() => {
    return ['All', 'None', ...valueOptions];
  }, [valueOptions]);

  const optionsWithLabelAndValue = useMemo(() => {
    return optionsWithAllAndNone.map((option) => ({
      label: option,
      value: getOptionVal(option),
    }));
  }, [optionsWithAllAndNone]);

  return (
    <div className="customer-filter-selector ml-2.5 mb-2.5 h-auto flex-col items-stretch">
      <div className="button-group__item button-group__item--label min-h-6	">
        {filterLabel}
      </div>
      <AutocompleteV2
        className="autocomplete-container--no-border autocomplete__fixed-height-30 button-group__item__autocomplete--value ms-2"
        onChange={(value) => {
          setCustomerFilter({ key: filterLabel, value: value as string });
        }}
        options={optionsWithLabelAndValue}
        value={customerFilter?.value}
      />
    </div>
  );
};

export default CustomerFilter;
