import { getLegacyFiltersFromFiltersState } from 'hooks/useFiltersState';
import { FormulaState, SearchState } from 'hooks/useSearch';
import { DashboardPanelType, DateSelection, SpanFilter } from 'types';
import {
  DataFrame,
  DataFrameMeta,
  DataTransformerConfig,
  filterLogqlFormulaMatchingLabels,
  getRollupByVisualization,
  getRollupToSecond,
  onPromiseError,
  sortAndLimitTimeseries,
} from 'utils';
import aggregateTimeSeries from './aggregateTimeSeries';
import aggregateTimeSeriesFormulaV2 from './aggregateTimeSeriesFormulaV2';
import { getUsedQueriesLabelForFormula } from './utils/formulaUtils';

type Args = {
  customerFilter?: { key: string; value: string };
  dataFormat?: DashboardPanelType;
  date: DateSelection;
  formulas?: FormulaState[];
  instant?: boolean;
  queries: SearchState[];
  spanFilter: SpanFilter;
  transformer: DataTransformerConfig[];
};

const limitToMap = {
  top: 'topk',
  bottom: 'bottomk',
};

const aggregateTimeSeriesMultipleV2 = async ({
  customerFilter,
  dataFormat,
  date,
  formulas,
  instant,
  queries,
  spanFilter,
  transformer,
}: Args) => {
  const datasetsQueries = await Promise.all(
    queries.map(async (query) => {
      const searchBarState = query.searchBarState;
      if (!searchBarState) return undefined;

      const { filters, traceIdSearch } = searchBarState;

      const {
        keyExists,
        facetRegex,
        selectedFacetRangeByName,
        selectedFacetValuesByName,
      } = getLegacyFiltersFromFiltersState(filters);
      const step = query.rollUpInSeconds
        ? getRollupToSecond(query.rollUpInSeconds as unknown as string)
        : getRollupByVisualization(date, 'bar');
      const data = await aggregateTimeSeries({
        ...query,
        aggregation: query.operation,
        aggregationField: query.measure,
        customerFilter,
        date,
        facetRegex,
        instant,
        keyExists,
        rollUpSeconds: step,
        selectedFacetRangeByName,
        selectedFacetValuesByName,
        spanFilter,
        traceIdSearch,
      }).catch(onPromiseError);
      const metricName =
        query.operation === 'distinctcount' ? 'count' : query.operation;
      const meta: DataFrameMeta = {
        refId: query.queryKey,
        step,
        type: dataFormat,
        labels: query.groupBys,
        metricName,
        aggregate: metricName,
        executedDate: date,
        queryType: 'query',
        unit: query.measure === 'duration_ns' ? 'ns' : 'number',
      };
      const initialData = { datasets: data, meta };
      const limit = {
        count: query.limitToValue,
        direction: limitToMap[query.limitTo],
      };

      const sortAndLimitTransformer = {
        id: 'sortAndLimitTimeseries',
        func: (dataFrame) => sortAndLimitTimeseries(dataFrame, limit),
      };
      transformer.splice(1, 0, sortAndLimitTransformer);
      return transformer.reduce((acc, currentTransformer) => {
        return currentTransformer.func(acc);
      }, initialData);
    }),
  );

  const datasetsFormula = await Promise.all(
    formulas.map(async (formula) => {
      const labelBitmap: { [key: string]: boolean } =
        getUsedQueriesLabelForFormula({
          formulaExpression: formula.expression,
          chartData: datasetsQueries,
        });
      const data = await aggregateTimeSeriesFormulaV2({
        customerFilter,
        date,
        formula,
        instant,
        queries,
        spanFilter,
      }).catch(onPromiseError);
      const meta: DataFrameMeta = {
        refId: formula.queryKey,
        type: dataFormat,
        executedDate: date,
        aggregate: `formula_${formula.queryKey}`,
        metricName: `formula_${formula.queryKey}`,
        queryType: 'formula',
        step: getRollupByVisualization(date, 'bar'),
        unit: 'number',
      };
      if (labelBitmap) {
        transformer.splice(transformer.length - 1, 0, {
          id: 'filterLogqlFormulaMatchingLabels',
          func: (dataFrame: DataFrame) =>
            filterLogqlFormulaMatchingLabels({
              dataFrame,
              labelBitmap,
            }),
          options: {},
        });
      }
      const intialData = { datasets: data, meta };
      return transformer.reduce((acc, transformer) => {
        return transformer.func(acc);
      }, intialData);
    }),
  );

  const datasets = [...datasetsQueries, ...datasetsFormula];
  return datasets;
};

export default aggregateTimeSeriesMultipleV2;
