import { useColumnsState, useTableOptions } from 'components';
import { useKeyExistsState } from 'hooks';
import React, { ReactElement, useEffect } from 'react';
import { useLogsTable } from 'screens/Logs/hooks';
import { LogsState } from 'types/logs-workbook';
import { BasicLogsSheetInner } from './BasicLogsSheet';

const BasicLogsTable = ({
  columnsState,
  customColumnsState,
  disableAddCustomColumn,
  enableWebSocket = true,
  isInView = true,
  isReload = true,
  logsState,
  onLogsLoaded,
  shouldDisableFilterActions,
  tableOptions,
  limit = 200,
}: {
  columnsState: ReturnType<typeof useColumnsState>;
  customColumnsState: ReturnType<typeof useKeyExistsState>;
  disableAddCustomColumn?: boolean;
  enableWebSocket?: boolean;
  isInView?: boolean;
  isReload?: boolean;
  logsState: LogsState;
  onLogsLoaded?: () => void;
  shouldDisableFilterActions?: boolean;
  tableOptions?: ReturnType<typeof useTableOptions>;
  limit?: number;
}): ReactElement => {
  const logsTable = useLogsTable();
  const { date } = logsState;

  useEffect(() => {
    if (!isInView || !isReload) return;
    logsTable
      .fetchLogs({ logsState, limit, enableWebSocket })
      .then(() => onLogsLoaded && onLogsLoaded());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, isInView, isReload]);

  return (
    <div className="dashboard-panel__logs">
      <BasicLogsSheetInner
        columnsState={columnsState}
        customColumnsState={customColumnsState}
        disableAddCustomColumn={disableAddCustomColumn}
        logs={logsTable.logs}
        logsTable={logsTable}
        logsState={logsState}
        renderToolbarLeft={() => null}
        onScrollEnd={() => {}}
        shouldDisableFilterActions={shouldDisableFilterActions}
        tableOptions={tableOptions}
      />
    </div>
  );
};

export default BasicLogsTable;
