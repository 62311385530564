import classnames from 'classnames';
import {
  DateWarningAlert,
  LeftSidebar,
  Loader,
  ShowSidebarTooltipButton,
  useLeftSidebarState,
} from 'components';
import { useFeatureFlag } from 'configcat-react';
import { useServicesPageStateContext } from 'context';
import { useRequest, useToggle } from 'hooks';
import React, { useEffect, useMemo } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import {
  getServices,
  getServiceGroupLabels,
  getServiceGroupLabelValues,
  traceLabelValuesV2,
} from 'requests';
import { Service as ServiceType } from 'types';
import { getIsAsmChecked } from 'utils';
import ServiceContainer from '../Service/ServiceContainer';
import ServicesMainServicesContainer from './ServicesMainServicesContainer';
import ServicesSidebar from './ServicesSidebar';
import { ServicesTab } from './utils';
import CustomerFilter from './CustomerFilter';
import useDebouncedEffect from 'use-debounced-effect';

type Props = {
  isAsm?: boolean;
  tab?: string;
  isInServiceDetails?: boolean;
};

const Services = ({ isAsm = false, tab, isInServiceDetails }: Props) => {
  const { value: showService7Day } = useFeatureFlag('showservice7day', false);
  const {
    value: isCustomerFilteringEnabled,
    loading: isCustomerFilteringConfigLoading,
  } = useFeatureFlag('isCustomerFilteringEnabled', false);
  const leftSidebarState = useLeftSidebarState('services');
  const { serviceNameAndHash } = useParams();
  const serviceNameAndHashParts = (serviceNameAndHash || '').split('_');
  const serviceHash =
    serviceNameAndHashParts[serviceNameAndHashParts.length - 1];
  const showSidebarToggle = useToggle(true);
  const location = useLocation();

  const {
    apmDateState,
    customerFilter,
    dependenciesForWriteStateToUrl,
    showInactiveServices,
    selectedFacetValuesByNameState,
    setCustomerFilter,
    setShowInactiveServices,
    chartGridKeysState,
    writeStateToUrl,
  } = useServicesPageStateContext();

  const kfMetricsAgentRequest = useRequest(traceLabelValuesV2);
  const telemetrySdkNameRequest = useRequest(traceLabelValuesV2);
  // TODO: This is so hack.
  useEffect(() => {
    if (isAsm) {
      selectedFacetValuesByNameState.selectOnlyFacetValue({
        name: 'kf_source',
        value: 'knight',
      });
    } else {
      if (location.pathname === '/apm/services') {
        selectedFacetValuesByNameState.selectOnlyFacetValue({
          name: 'kf_source',
          value: 'apm',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAsm]);

  const isAsmChecked = useMemo(
    () => getIsAsmChecked(selectedFacetValuesByNameState.state),
    [selectedFacetValuesByNameState.state],
  );

  const dateState = apmDateState;
  const [date] = dateState;

  useDebouncedEffect(
    () => {
      telemetrySdkNameRequest.call({
        customerFilter: customerFilter,
        date,
        name: 'telemetry_sdk_name',
        selectedFacetValuesByName: selectedFacetValuesByNameState.state,
        serviceHash,
      });
      kfMetricsAgentRequest.call({
        customerFilter: customerFilter,
        date,
        name: 'kf_metrics_agent',
        selectedFacetValuesByName: selectedFacetValuesByNameState.state,
        serviceHash,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    { ignoreInitialCall: false, timeout: 100 },
    [customerFilter, date, selectedFacetValuesByNameState.state, serviceHash],
  );

  useEffect(() => {
    writeStateToUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dependenciesForWriteStateToUrl]);

  const [params] = useSearchParams();
  const isServiceFromDatabasesList = useMemo(() => {
    const isDatabase = params?.get('activeTab') === ServicesTab.db;
    return isDatabase;
  }, [params]);

  const serviceTab = useMemo(() => {
    if (isServiceFromDatabasesList) {
      return ServicesTab.db;
    }
    if (tab) {
      return tab;
    }
    return null;
  }, [tab, isServiceFromDatabasesList]);

  const getServicesRequest = useRequest(getServices);
  const getServiceGroupLabelRequest = useRequest(getServiceGroupLabels);
  const getServiceGroupValuesRequest = useRequest(getServiceGroupLabelValues);

  const serviceByHash: Record<string, ServiceType> = useMemo(
    () =>
      (getServicesRequest.result || []).reduce(
        (obj, service) => ({ ...obj, [service.hash]: service }),
        {},
      ),
    [getServicesRequest.result],
  );

  useEffect(() => {
    if (isCustomerFilteringEnabled) {
      getServiceGroupLabelRequest.call();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCustomerFilteringEnabled]);

  const selectedLabel = useMemo(() => {
    if (
      getServiceGroupLabelRequest.result &&
      getServiceGroupLabelRequest.result.length
    ) {
      const firstLabel = getServiceGroupLabelRequest.result[0];
      return firstLabel;
    }
    return null;
  }, [getServiceGroupLabelRequest.result]);

  useDebouncedEffect(
    () => {
      if (selectedLabel) {
        getServiceGroupValuesRequest.call({ label: selectedLabel });
      }
    },
    50,
    [selectedLabel],
  );

  const service = serviceByHash[serviceHash];

  if (isCustomerFilteringConfigLoading) {
    return <Loader className="h-full" isLoading={true} />;
  }

  const isCustomerFilterSelected = Boolean(customerFilter?.value);

  const showServicesLandingPage =
    !isCustomerFilteringEnabled || isCustomerFilterSelected;

  return (
    <div
      className={classnames({
        services: true,
        'services--disable-7-day': !showService7Day,
      })}
    >
      <LeftSidebar leftSidebarState={leftSidebarState}>
        <>
          {isCustomerFilteringEnabled && !serviceHash && (
            <CustomerFilter
              customerFilter={customerFilter}
              filterLabel={selectedLabel}
              valueOptions={getServiceGroupValuesRequest.result || []}
              setCustomerFilter={setCustomerFilter}
            />
          )}
          {showServicesLandingPage ? (
            <ServicesSidebar
              customerFilter={customerFilter}
              date={date}
              isAsmChecked={isAsmChecked}
              selectedFacetValuesByNameState={selectedFacetValuesByNameState}
              serviceHash={serviceHash}
              serviceDistinctLabels={service?.distinctLabels || {}}
              showSidebarToggle={showSidebarToggle}
              serviceTab={serviceTab}
              telemetrySdkName={
                telemetrySdkNameRequest.result?.[0]?.groupVal
                  ?.telemetry_sdk_name
              }
              isInServiceDetails={isInServiceDetails}
            />
          ) : null}
        </>
      </LeftSidebar>

      <div className="services__main">
        <DateWarningAlert
          className={classnames({
            services__banner: true,
            'services__banner--no-left-padding': true,
          })}
          date={date}
        />

        {serviceHash ? (
          <ServiceContainer
            apmDateState={apmDateState}
            chartGridKeysState={chartGridKeysState}
            customerFilter={customerFilter}
            isAsmChecked={isAsmChecked}
            getServicesRequest={getServicesRequest}
            key={serviceHash}
            kfMetricsAgentName={
              kfMetricsAgentRequest.result?.[0]?.groupVal?.kf_metrics_agent
            }
            leftSidebarState={leftSidebarState}
            selectedFacetValuesByNameState={selectedFacetValuesByNameState}
            serviceHash={serviceHash}
            service={service}
            serviceByHash={serviceByHash}
            telemetrySdkName={
              telemetrySdkNameRequest.result?.[0]?.groupVal?.telemetry_sdk_name
            }
          />
        ) : showServicesLandingPage ? (
          <ServicesMainServicesContainer
            customerFilter={customerFilter}
            dateState={dateState}
            isAsmChecked={isAsmChecked}
            leftSidebarState={leftSidebarState}
            serviceTab={serviceTab}
            showInactiveServices={showInactiveServices}
            selectedFacetValuesByNameState={selectedFacetValuesByNameState}
            setCustomerFilter={setCustomerFilter}
            setShowInactiveServices={setShowInactiveServices}
          />
        ) : (
          <div className="flex flex-col height-100-per w-full">
            {leftSidebarState.width === 0 ? (
              <div className="flex justify-start">
                <ShowSidebarTooltipButton
                  className="customer-filter__show-sidebar"
                  onClick={leftSidebarState.show}
                />
                <div />
              </div>
            ) : null}
            <div className="flex-1 flex items-center justify-center">
              <div className="facet-picker__values__placeholder italic text-2xl">
                Select Service Group Filter To View Available Services
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Services;
