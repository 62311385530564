import { BasicLogsSheet } from 'components';
import { delimiter } from 'kfuse-constants';
import React, { useMemo } from 'react';
import { DateSelection } from 'types';
import { getFacetKey } from 'utils';

type Props = {
  date: DateSelection;
  functionArn: string;
};

const ServerlessRightSidebarLogs = ({ date, functionArn }: Props) => {
  const logsState = useMemo(() => {
    const key = 'function_arn';
    const facetKeyForFunctionArn = getFacetKey({
      component: 'Additional',
      name: key,
      type: 'string',
      displayName: '',
    });
    const facetKeyForSource = getFacetKey({
      component: 'Core',
      name: 'source',
      type: 'string',
      displayName: '',
    });
    return {
      date,
      ...{
        selectedFacetValues: {
          [`${facetKeyForFunctionArn}${delimiter}${functionArn}`]: 1,
          [`${facetKeyForSource}${delimiter}lambda`]: 1,
        },
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  return (
    <div className="serverless__sidebar__logs">
      <BasicLogsSheet logsState={logsState} renderToolbarLeft={() => null} />
    </div>
  );
};

export default ServerlessRightSidebarLogs;
