import classNames from 'classnames';
import {
  AutocompleteV2,
  Button,
  CheckboxWithLabel,
  Input,
  Loader,
  useToaster,
} from 'components';
import { useRequest } from 'hooks';
import React, { ReactElement, useEffect, useState } from 'react';
import { X } from 'react-feather';
import { getLogsFavoriteFacetGroups } from 'requests';
import {
  addLogsFavoriteFacet,
  editLogsFavoriteFacet,
  removeLogsFavoriteFacet,
} from './requests';
import { FavoriteFacetExtend } from './types';

const LogsFacetExplorerEditFavorite = ({
  bulkFacets = [],
  closeModal,
  enableRemoveFavorite = false,
  enableBulkMarkFavorite = false,
  facet,
  facetName = '',
  facetSource = '',
  facetType = '',
  onSaveFavoriteSuccess,
}: {
  bulkFacets?: FavoriteFacetExtend[];
  closeModal: () => void;
  enableRemoveFavorite?: boolean;
  enableBulkMarkFavorite?: boolean;
  facet?: FavoriteFacetExtend;
  facetName?: string;
  facetSource?: string;
  facetType?: string;
  onSaveFavoriteSuccess?: (group: string) => void;
}): ReactElement => {
  const { addToast } = useToaster();
  const editLogsFavoriteFacetRequest = useRequest(editLogsFavoriteFacet);
  const addLogsFavoriteFacetRequest = useRequest(addLogsFavoriteFacet);
  const removeLogsFavoriteFacetRequest = useRequest(removeLogsFavoriteFacet);
  const favoriteGroupListRequest = useRequest((args) =>
    getLogsFavoriteFacetGroups(args).then((groups) =>
      groups.map((g) => ({ label: g, value: g })),
    ),
  );
  const [editType, setEditType] = useState<'existing' | 'new'>('existing');
  const [facetDetails, setFacetDetails] = useState<{
    name: string;
    group: string;
    rememberSource: boolean;
  }>({ name: '', group: '', rememberSource: false });

  const onSaveFavorite = () => {
    if (!facetDetails.name) {
      addToast({
        text: 'Please enter a name for the favorite facet.',
        status: 'error',
      });
      return;
    }
    if (!facetDetails.group) {
      addToast({
        text: 'Please select a group for the favorite facet.',
        status: 'error',
      });
      return;
    }

    if (facet) {
      editLogsFavoriteFacetRequest
        .call({
          displayName: facet.displayName,
          facetGroup: facet.group,
          newFacetGroup: facetDetails.group,
          newDisplayName: facetDetails.name,
        })
        .then(() => {
          onSaveFavoriteSuccess?.(facetDetails.group);
          addToast({
            text: 'Favorite facet updated successfully',
            status: 'success',
          });
          closeModal();
        });
    } else {
      addLogsFavoriteFacetRequest
        .call({
          source: facetDetails.rememberSource ? facetSource : '',
          displayName: facetDetails.name,
          facetGroup: facetDetails.group,
          facet: facetName,
          datatype: facetType,
        })
        .then(() => {
          onSaveFavoriteSuccess?.(facetDetails.group);
          addToast({
            text: 'Favorite facet added successfully',
            status: 'success',
          });
          closeModal();
        });
    }
  };

  const onRemoveFavorite = () => {
    removeLogsFavoriteFacetRequest
      .call({ displayName: facet.displayName, facetGroup: facet.group })
      .then((res) => {
        onSaveFavoriteSuccess?.(facetDetails.group);
        addToast({
          text: 'Favorite facet removed successfully',
          status: 'success',
        });
        closeModal();
      });
  };

  const onMarkFavoriteBulk = async () => {
    if (bulkFacets.length === 0) {
      addToast({ text: 'No facets to mark as favorite', status: 'error' });
      return;
    }
    const markedFacets = await Promise.all(
      bulkFacets.map((f) =>
        addLogsFavoriteFacetRequest.call({
          facet: f.name,
          facetGroup: facetDetails.group,
          datatype: f.type,
          displayName: f.name,
          source: facetDetails.rememberSource ? facetSource : '',
        }),
      ),
    );

    addToast({
      text: `${markedFacets.length} facets marked as favorite successfully from ${facetSource}`,
      status: 'success',
    });
    onSaveFavoriteSuccess?.(facetDetails.group);
    closeModal();
  };

  useEffect(() => {
    favoriteGroupListRequest.call({ limit: 1000 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (facet) {
      setEditType('existing');
      setFacetDetails({
        name: facet.displayName,
        group: facet.group,
        rememberSource: Boolean(facet.source),
      });
    } else {
      setEditType('new');
      setFacetDetails({
        name: facetName,
        group: facetSource,
        rememberSource: Boolean(facetSource),
      });
    }
  }, [facet, facetName, facetSource]);

  return (
    <div
      className="logs__analytics__export-dashboard"
      data-testid="export-dashboard"
    >
      <div className="logs__analytics__export-dashboard__header">
        <div className="logs__analytics__export-dashboard__header__title">
          Edit Favorite
        </div>
        <div
          className="logs__analytics__export-dashboard__header__close"
          onClick={closeModal}
        >
          <X />
        </div>
      </div>
      <Loader
        isLoading={
          editLogsFavoriteFacetRequest.isLoading ||
          addLogsFavoriteFacetRequest.isLoading ||
          removeLogsFavoriteFacetRequest.isLoading
        }
      >
        <div className="logs__analytics__export-dashboard__label"></div>
        <div className="button-group mb-4">
          <div
            className={classNames({
              'button-group__item': true,
              'button-group__item--active': editType === 'new',
            })}
          >
            <button onClick={() => setEditType('new')}>New Group</button>
          </div>
          <div
            className={classNames({
              'button-group__item': true,
              'button-group__item--active': editType === 'existing',
            })}
          >
            <button onClick={() => setEditType('existing')}>
              Existing Group
            </button>
          </div>
        </div>
        <div>
          {editType === 'new' && (
            <>
              <div className="logs__analytics__export-dashboard__label">
                Enter the name of the new group.
              </div>
              <Input
                onChange={(val) =>
                  setFacetDetails({ ...facetDetails, group: val })
                }
                placeholder="Give group a name"
                type="text"
                value={facetDetails.group}
                size="4"
                variant="default"
              />
            </>
          )}
        </div>
        <div className="logs__analytics__export-dashboard__select-dashboard">
          {editType === 'existing' && (
            <>
              <div className="logs__analytics__export-dashboard__label">
                Select in which group the favorite facet will be.
              </div>
              <AutocompleteV2
                options={favoriteGroupListRequest.result || []}
                onChange={(val: string) =>
                  setFacetDetails({ ...facetDetails, group: val })
                }
                placeholder="Select existing group"
                value={facetDetails.group}
                isLoading={favoriteGroupListRequest.isLoading}
              />
            </>
          )}
        </div>
        {!enableBulkMarkFavorite ? (
          <div>
            <div className="logs__analytics__export-dashboard__label">
              Enter the display name of the favorite facet.
            </div>
            <Input
              onChange={(val: string) =>
                setFacetDetails({ ...facetDetails, name: val })
              }
              placeholder="Give favorite facet a display name"
              type="text"
              value={facetDetails.name}
              size="4"
              variant="default"
            />
          </div>
        ) : null}
        {facetSource ? (
          <div className="pt-4">
            <CheckboxWithLabel
              label={
                <div>
                  Remember source? (This will allow you to include the source{' '}
                  <span className="font-bold">{facetSource}</span> in facet)
                </div>
              }
              dataTestId="remember-facet-source"
              value={facetDetails.rememberSource}
              onChange={(val) =>
                setFacetDetails({ ...facetDetails, rememberSource: val })
              }
            />
          </div>
        ) : null}
        <div className="logs__analytics__export-dashboard__footer">
          <Button variant="outline" size="sm" onClick={closeModal}>
            Cancel
          </Button>
          {enableRemoveFavorite && facet && (
            <Button
              variant="outline"
              size="sm"
              className="ml-2"
              onClick={onRemoveFavorite}
            >
              Remove Favorite
            </Button>
          )}
          <Button
            variant="default"
            size="sm"
            className="ml-2"
            onClick={() => {
              if (enableBulkMarkFavorite) {
                onMarkFavoriteBulk();
              } else {
                onSaveFavorite();
              }
            }}
          >
            {enableBulkMarkFavorite
              ? `Mark ${bulkFacets.length} Facets as Favorite`
              : 'Save Favorite'}
          </Button>
        </div>
      </Loader>
    </div>
  );
};

export default LogsFacetExplorerEditFavorite;
