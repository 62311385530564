import { useColumnsState } from 'components';
import {
  useServicesPageStateContext,
  useTracesPageStateContext,
} from 'context';
import { useFeatureFlag } from 'configcat-react';
import { useSelectedFacetValuesByNameState } from 'hooks';
import React, { MutableRefObject, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ChartGridKeysState,
  DateSelection,
  EventListProps,
  Service,
  SpanFilter,
} from 'types';
import useDebouncedEffect from 'use-debounced-effect';
import getFiltersForTraces from './getFiltersForTraces';
import getServiceTabTableColumns from './getServiceTabTableColumns';
import ServiceTabChartGrid from './ServiceTabChartGrid';
import ServiceTabTable from './ServiceTabTable';
import { Property, SidebarState } from './types';
import useKpisBySpanNameRequest from './useKpisBySpanNameRequest';

type Props = {
  chartGridKeysState: ChartGridKeysState;
  colorsByServiceName?: Record<string, string>;
  date: DateSelection;
  eventListRef?: MutableRefObject<EventListProps[] | null>;
  isServiceFromDatabasesList?: boolean;
  isDownStream?: boolean;
  label: string;
  property: string;
  selectedFacetValuesByNameState: ReturnType<
    typeof useSelectedFacetValuesByNameState
  >;
  service: string;
  serviceByHash: Record<string, Service>;
  serviceHash: string;
  setDate: (date: DateSelection) => void;
  setSidebar: (sidebar: SidebarState) => void;
  setProperty: (property: Property) => void;
};

const ServiceTab = ({
  chartGridKeysState,
  date,
  eventListRef,
  isServiceFromDatabasesList,
  isDownStream,
  label,
  property,
  selectedFacetValuesByNameState,
  serviceByHash,
  serviceHash,
  setDate,
  setSidebar,
}: Props) => {
  const tracesPageState = useTracesPageStateContext();
  const { customerFilter } = useServicesPageStateContext();
  const navigate = useNavigate();
  const { value: isLatencyBoundedToMinMax, loading: isLatencyConfigLoading } =
    useFeatureFlag('isLatencyBoundedToMinMax', false);

  const navigateToTracesPageHander = useCallback(
    (value: string) => () => {
      const { tracesState } = tracesPageState;
      const { dateState, filtersState, spanFilters } = tracesState;
      const [_, setDate] = dateState;
      const { setState } = filtersState;
      const { setSpanFilter } = spanFilters;

      // TODO: this doesn't work yet'
      setDate(date);
      setState(
        getFiltersForTraces({
          isServiceFromDatabasesList,
          property,
          serviceHash,
          value,
        }),
      );
      if (customerFilter) {
        tracesState.setCustomerFilter(customerFilter);
      }

      if (isServiceFromDatabasesList) {
        setSpanFilter(SpanFilter.allSpans);
      }

      navigate(`/apm/traces/list`);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [date, navigate, property, serviceHash],
  );

  const kpisBySpanNameRequest = useKpisBySpanNameRequest({
    isDownStream,
  });

  const { fetchSingleColumn, isReady, kpisBySpanName } = kpisBySpanNameRequest;

  const colorMap = kpisBySpanNameRequest.colorMap;

  const columns = useMemo(
    () =>
      getServiceTabTableColumns({
        colorMap,
        kpisBySpanName,
        isLatencyBoundedToMinMax,
        isServiceFromDatabasesList,
        navigateToTracesPageHander,
        property,
        serviceByHash,
      }),
    [
      colorMap,
      kpisBySpanName,
      isLatencyBoundedToMinMax,
      isServiceFromDatabasesList,
      navigateToTracesPageHander,
      property,
      serviceByHash,
    ],
  );

  const columnsState = useColumnsState({
    columns,
    initialState: {
      resizedWidths: {},
      selectedColumns: {
        name: 1,
        spanType: 1,
        requestsPerSecond: 1,
        p50latency: 1,
        p99latency: 1,
        maxlatency: 1,
        errorRate: 1,
        apdex: 1,
      },
    },
    key: 'service-table',
    onSelectedColumnToggle: ({ key, isSelected }) => {
      if (isSelected) {
        fetchSingleColumn({
          date,
          isDownStream,
          key,
          property,
          eventList: property === Property.version ? eventListRef?.current : [],
          serviceHash,
          selectedColumns: { [key]: 1 },
          selectedFacetValuesByName: selectedFacetValuesByNameState.state,
        });
      }
    },
  });

  useDebouncedEffect(
    () => {
      if (columnsState.isReady && !isLatencyConfigLoading) {
        kpisBySpanNameRequest.fetch({
          date,
          eventList: property === Property.version ? eventListRef?.current : [],
          isDownStream,
          isLatencyBoundedToMinMax,
          property,
          selectedColumns: columnsState.state.selectedColumns,
          selectedFacetValuesByName: selectedFacetValuesByNameState.state,
          serviceHash,
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    {
      ignoreInitialCall: false,
      timeout: 100,
    },
    [
      columnsState.isReady,
      date,
      isLatencyConfigLoading,
      isLatencyBoundedToMinMax,
      property,
      selectedFacetValuesByNameState.state,
    ],
  );

  return (
    <div className="service__tab">
      <div className="service__section">
        {isReady ? (
          <ServiceTabChartGrid
            chartGridKeysState={chartGridKeysState}
            colorMap={colorMap}
            date={date}
            eventListRef={eventListRef}
            isDownStream={isDownStream}
            property={property}
            selectedFacetValuesByNameState={selectedFacetValuesByNameState}
            serviceHash={serviceHash}
            serviceByHash={serviceByHash}
            setDate={setDate}
          />
        ) : null}
      </div>
      <div className="service__section">
        <div className="service__section__header">{label}</div>
        <div className="service__section__body">
          <ServiceTabTable
            columnsState={columnsState}
            kpisBySpanNameRequest={kpisBySpanNameRequest}
            serviceByHash={serviceByHash}
          />
        </div>
      </div>
    </div>
  );
};

export default ServiceTab;
